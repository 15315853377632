import { datesLib } from "@/mixins/datesLib";
import { osInfo } from "@/mixins/osInfo";
import { pushMessageHelper } from "@/mixins/pushMessageHelper.js"

export const globalSettings = {
	mixins: [datesLib, pushMessageHelper, osInfo],
	data() {
		return {
			isProductionEnvironment: process.env.NODE_ENV != 'development',
			apiUrl: process.env.NODE_ENV != 'development' ? "/Api" : "http://localhost:5006/Api",			// Uncomment if the target API is the Net Core web API.

			dirtyFixes: {
				sliderWidthFix: 30
			},

			notifPermission: {
				denied: "denied",
				granted: "granted",
				default: "default"
			},
			
			enumPresenter: {
				getName: function(presenter, val) {
					var element = presenter.find(el => el.value == val);
					return (element != null ? element.name : "???");
				},
				recurrenceTypes: [
					{ value: 0, name: "None" },
					{ value: 2, name: "Daily" },
					{ value: 3, name: "Weekly" },
					{ value: 4, name: "Monthly" },
					{ value: 5, name: "Yearly" },
					{ value: 6, name: "Day of Week" },
					{ value: 7, name: "Day of Month" },
					{ value: 1, name: "Custom" }
				],
				recurrenceUnit: [
					{ value: 3, name: "Day" },
					{ value: 4, name: "Month" },
					{ value: 5, name: "Year" }
				],
				// In the following array, if the displayed day order is to ne changed (for example Sunday as first day, only the value of daysMapIndex field must be changed, pointing to the corresponding element in the days map array).
				daysOfWeek: [
					{daysMapIndex: 0, name: "Monday", shortName: "M"},
					{daysMapIndex: 1, name: "Tuesday", shortName: "T"},
					{daysMapIndex: 2, name: "Wednesday", shortName: "W"},
					{daysMapIndex: 3, name: "Thursday", shortName: "T"},
					{daysMapIndex: 4, name: "Friday", shortName: "F"},
					{daysMapIndex: 5, name: "Sathurday", shortName: "S"},
					{daysMapIndex: 6, name: "Sunday", shortName: "S"}
				],
				eventFilters: [
					{ value: 1, name: "Active" },
					{ value: 2, name: "Inactive" },
					{ value: 3, name: "All" }
				],
			}


		}
	},
	methods: {
		showWait: function(show) {
			this.goptions.waitPopupVisible = show;
		}
	}
}
