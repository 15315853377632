import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//createApp(App).use(store).use(router).mount('#app')

import Buefy from 'buefy'
import "./assets/css/main.scss"

// Date-time picker
// Ref: https://github.com/chronotruck/vue-ctk-date-time-picker
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import ky from "ky";

import { globalSettings } from "@/mixins/globalSettings";

require("./assets/css/site.css");
require("./assets/css/style.css");


// App initialization
async function initilizeApp() {
	const app = createApp(App);
	app.use(store).use(router);

	// app.mixin(globalSettings);	// Set globalSettings as a global mixin 
	// app.mixin(auth);	// Set auth as a global mixin

	app.use(Buefy);

	// Register global components
	app.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

	app.config.globalProperties.$ky = ky;	// Add ky to the app's global properties

	// await initialSetup.initializeAppState(store);

	app.mount('#app');
}

initilizeApp();
