<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Test</div>
		<div>
			<button id="testEditButton" @click="doTestEdit()">Edit...</button>
		</div>
		<div style="margin-top: 20px;">
			<a href="#" v-on:click.prevent="doConvertToDate('2021-01-12T08:00:00')" class="button">Iso8601String to Date()</a>
		</div>
		<div style="margin-top: 20px;">
			<a href="#" v-on:click.prevent="doShowWaitPopup()" class="button">Show Wait popup</a>
		</div>

		<ModalPopupFade columns="6" transDuration="0.5s" popTitle="Edit event" :visible="editFormVisible" @askClose="editCancel">
			<EventEditor :targetEvent="editEventObj" @doCancel="editCancel" @doSave="editSave" />
		</ModalPopupFade>

		<div style="margin-top: 20px;">
			{{ formatIsoDateString("2021-03-01 10:11:12", "ymd", "/", true) }}
		</div>

		<div style="margin-top: 20px;">
			Running version: {{ isProductionEnvironment ? "Production" : "Development" }}
		</div>

		<div style="margin-top: 20px;">
			<a href="#" v-on:click.prevent="doTestPromiseReturn()" class="button">Test promise return</a>
		</div>

	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import EventEditor from "@/components/EventEditor.vue";
import ModalPopupFade from "@/components/controls/ModalPopupFade.vue";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'test',
	mixins: [globalSettings],
	components: {
		ModalPopupFade, EventEditor
	},
	data: function() {
		return {
			editFormVisible: false,
			editEventObj: null
		}
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		doTestPromiseReturn: async function() {
			// this.checkUserNamePassword()
			// .then((responseJson) => {
			// 	console.log(responseJson);
			// })
			// .catch((err) => {
			// 	alert("Error: " + err.message);
			// });

			//var result = Promise.resolve(this.checkUserNamePassword());
			//var result = this.checkUserNamePassword();
			//console.log(result);

			try {
				var result = await this.checkUserNamePassword();
				console.log(result);
			}
			catch(ex) {
				console.error("xxxxxxx");
			}

		},
		checkUserNamePassword: async function(showAlert) {
			return this.$ky(this.apiUrl + "/CheckUserPassword", {
				method: "GET",
				searchParams: {
					user: "serban",
					password: 6
				},
				cache: "no-cache",
				timeout: 5000,
				retry: 1
			})
			.json()
			//.then((result) => { result["xxx"] = "yyy"; })
			;
		},
		doShowWaitPopup: function() {
			this.showWait(true);
		},
		doConvertToDate: function(iso8601DateStr) {
			var date = this.getDateFromIso8601String(iso8601DateStr);
			console.log(date);
			console.log(this.dateToIsoStr(date, "DT", "/", "_", "---"));
			console.log(this.datesDiff(this.getDateFromIso8601String("2021-01-12T08:00:00"), this.getDateFromIso8601String("2021-01-10T08:00:00")));
			console.log(this.datesDiff(new Date(), this.getDateFromIso8601String("2021-01-12T08:00:00")));
		},
		editCancel: function() {
			console.log("..... Cancel");
			this.editFormVisible = false;
		},
		editSave: function(eventObj) {
			console.log("..... Save");
			console.log(eventObj);

			this.editFormVisible = false;
		},
		doTestEdit: function() {
			this.$ky(this.apiUrl + "/GetEvent", {
				method: "GET",
				searchParams: {
					eventId: 18
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.editEventObj = responseJson;
					this.editFormVisible = true;
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		}
	}
}
</script>

<style>
	div.modalContainer {
		position: absolute;
		text-align: center;
		left: 0px; top: 0px; right: 0px; bottom: 0px;
		background-color: #000000;
		opacity: 1;
		z-index: 999;
		overflow-x: auto;
		overflow-y: auto;
	}

	div.editForm {
		display: inline-block;
		margin-top: 30px;
		margin-bottom: 30px;
		top: 20%;
		border: 2px solid red;
		opacity: 1;
		background-color: white;
		color: red;
	}
</style>