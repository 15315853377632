<template>
	<div style="border: 1px solid black; padding: 5px; border-radius: 10px; background-color: white;">
		<div v-bind:style="getTextStyleEventTitle">{{ eventName }}</div>

		<div v-bind:style="sliderContainerStyle">

			<div v-bind:style="barStyle" @click.prevent="onBarClicked"></div>

			<div v-bind:style="sliderThumbStyle">
				<div v-bind:style="sliderThumbFillStyle"></div>
			</div>

			<!-- <div v-bind:style="getTextStyleCell_00">{{ eventName }}</div> -->

			<div v-bind:style="getTextStyleCell_01">
				{{ dateEnd }}
				<br class="is-hidden-desktop"/>
				<span class="is-hidden-mobile">, </span>
				Days left: {{ remainingDays }}
			</div>

			<!-- <div v-bind:style="getTextStyleCell_10">Remaining days: {{ remainingDays }}</div> -->

			<div v-bind:style="getTextStyleCell_00">Start before: <br class="is-hidden-tablet"/>{{ startBeforeDays }} days</div>

			<div class="occurrenceEditor" v-if="editorVisible">

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Occurrence",
	props: {
		containerWidthPercent: {default: 100},
		containerBackgroundColor: {default: "transparent"},
		containerBorder: {default: "1px #0000ff solid"},
		containerBorderRadius: {default: 10},
		containerPadding: {default: function() {return {top: 10, bottom: 10}}},
		barHeight: {default: 30},
		barRadius: {default: 10},
		thumbTop: {default: 10},
		thumbHeight: {default: 50},
		thumbRadius: {default: 10},
		thumbOpacity: {default: 0.4},

		eventId: {default: 0},
		resolution: {default: "None"},
		markingActionDateStr: {default: "2020-02-21"},
		midDateTime: {default: "2020-02-06 12:00:00"},

		gradientStops: {default: function() { return [
			{
				"offsetPercent": 19.999998,
				"stopColor": "rgb(0,150,0)",
				"stopOpacity": 255
			},
			{
				"offsetPercent": 40.999996,
				"stopColor": "rgb(255,255,0)",
				"stopOpacity": 255
			},
			{
				"offsetPercent": 70.000004,
				"stopColor": "rgb(255,0,0)",
				"stopOpacity": 255
			}
		]}},
		dateThumbLeftRatio: {default: 0.11671296296296296},
		dateThumbWidthRatio: {default: 0.033332947530864198},
		textFontSize: {default: 18},
		eventName: {default: "Event name"},
		dateEnd: {default: "??-??-????"},
		remainingDays: {default: 0},
		startBeforeDays: {default: 0}
	},
	data: function() {
		return {
			unit: "px",
			editorVisible: false,
			calcAttr: {
				containerHeight: 150,
				barLeft: 0,
				barTop: 0,
				barWidthPercent: 100,
				barBorder: "2px #555555 solid",
				thumbLeftPercent: 0,
				thumbWidthPercent: 0,
				thumbBorder: "1px #000000 solid",
				textLine1Top: 80,
				textLine2Top: 110
			}
		};
	},
	created: function() {
		// Compute the derived attributes.
		this.calcAttr.barTop = (this.thumbHeight - this.barHeight) / 2 + this.thumbTop;
		this.calcAttr.textLine1Top = this.thumbTop + this.thumbHeight + 5;
		this.calcAttr.textLine2Top = this.calcAttr.textLine1Top + 1.4 * this.textFontSize;
		this.calcAttr.containerHeight = this.calcAttr.textLine2Top + this.textFontSize + this.thumbTop;
	},
	computed: {
		sliderContainerStyle: function() {
			return {
				position: "relative",
				width: this.containerWidthPercent + "%",
				height: this.calcAttr.containerHeight + this.unit,
				border: this.containerBorder,
				"font-size": this.textFontSize + this.unit,
				"background-color": this.containerBackgroundColor,
				"border-radius": this.containerBorderRadius + this.unit,
				"overflow-x": "hidden",
				"overflow-y": "hidden"
			}
		},
		barStyle: function() {
			return {
				position: "absolute",
				border: this.calcAttr.barBorder,
				height: this.barHeight + this.unit,
				left: this.calcAttr.barLeft + this.unit,
				//width: this.calcAttr.barWidthPercent + "%",
				right: 0 + this.unit,
				top: this.calcAttr.barTop + this.unit,
				"border-radius": this.barRadius + this.unit,
				"background-image": this.getBarGradients(),
				cursor: "pointer"
			}
		},
		sliderThumbStyle: function() {
			return {
				position: "absolute",
				border: this.calcAttr.thumbBorder,
				height: this.thumbHeight + this.unit,
				top: this.thumbTop + this.unit,
				"border-radius": this.thumbRadius + this.unit,
				width: 100 * this.dateThumbWidthRatio + "%",
				left: 100 * this.dateThumbLeftRatio + "%",
				"overflow-x": "hidden",
				"overflow-y": "hidden"
			}
		},
		sliderThumbFillStyle: function() {
			return {
				width: "100%",
				height: "100%",
				"background-color": "#555555",
				opacity: this.thumbOpacity
			}
		},
		getTextStyleEventTitle: function() {
			return {
				"font-size": this.textFontSize + this.unit,
				"font-weight": "bold",
				"text-align": "left",
				"margin-top": "20px"
			}
		},
		getTextStyleCell_00: function() {
			return {
				position: "absolute",
				left: 0 + this.unit,
				top: this.calcAttr.textLine1Top + this.unit,
				"font-weight": "normal"
			}
		},
		getTextStyleCell_01: function() {
			return {
				position: "absolute",
				right: 0 + this.unit,
				top: this.calcAttr.textLine1Top + this.unit,
				"font-weight": "normal",
				"text-align": "right"
			}
		},
		getTextStyleCell_10: function() {
			return {
				position: "absolute",
				left: 0 + this.unit,
				top: this.calcAttr.textLine2Top + this.unit,
				"font-weight": "normal"
			}
		},
		getTextStyleCell_11: function() {
			return {
				position: "absolute",
				right: 0 + this.unit,
				top: this.calcAttr.textLine2Top + this.unit,
				"font-weight": "normal"
			}
		}
	},
	methods: {
		getBarGradients: function() {
			var retVal = "linear-gradient(to right,";
			this.gradientStops.forEach(gradientStop => {
				// Set specific color for "Done" and "Dismissed" occurrence
				if (this.resolution == "Done") {
					gradientStop.stopColor = "rgb(100,255,100)";
				}
				else if (this.resolution == "Dismissed") {
					gradientStop.stopColor = "rgb(200,200,200)";
				}
				retVal += gradientStop.stopColor + " " + gradientStop.offsetPercent + "%"  + ",";
			});
			// Remove the final ','
			retVal = retVal.slice(0, -1);
			retVal += ")";
			return retVal;
		},
		onBarClicked: function(ev) {
			this.$emit("editOccurrence", {eventId: this.eventId, currentResolution: this.resolution, occurrenceDateEnd: this.dateEnd});

			//this.editorVisible = true;
		}
	}
}
</script>

<style>
	div.occurrenceEditor {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		background-color: #888888;
	}
</style>