<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Future events</div>

		<div class="section-header">
			<div class="columns is-vcentered">
				<div class="column is-narrow"><label>Display until</label></div>
				<div class="column is-hidden-mobile is-narrow">
					<VueCtkDateTimePicker
						v-model="dtpicker.privateDateStr" 
						:first-day-of-week="dtpicker.firstDayOfWeek" 
						:label="dtpicker.label" 
						only-date
						input-size="sm" 
						no-label
						color="green"
						noClearButton
						no-button-now
						format="YYYY-MM-DD"
						formatted="YYYY-MM-DD"
						@validate="onDtPickerValidateClicked()"
						@is-hidden="onDtPickerHidden()"
						>
					</VueCtkDateTimePicker>
				</div>
				<div class="column is-hidden-tablet is-narrow">
					<VueCtkDateTimePicker
						v-model="dtpicker.privateDateStr" 
						:first-day-of-week="dtpicker.firstDayOfWeek" 
						:label="dtpicker.label" 
						only-date
						input-size="sm" 
						no-label
						color="green"
						noClearButton
						inline
						format="YYYY-MM-DD"
						formatted="YYYY-MM-DD"
						@validate="onDtPickerValidateClicked()"
						@is-hidden="onDtPickerHidden()"
						@input="onDtPickerInput"
						>
					</VueCtkDateTimePicker>
				</div>
				<div class="column has-text-weight-normal has-text-right">
					<a href="#" v-on:click.prevent="getFuture()" class="button">Refresh</a>
				</div>
			</div>
		</div>

		<table class="table is-bordered is-narrow is-hoverable" style="margin-top: 20px;">
			<thead>
				<tr style="background-color: #eeeeee">
					<th class="tableHead">Event</th>
					<th class="tableHead">Occurrence Start</th>
					<th class="tableHead">Occurrence End</th>
					<th class="tableHead">Days until reminder start</th>
					<th class="tableHead">Days until due date</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(occ, occIndex) in occurrences">
					<td class="tableCell">{{ getParentEventName(occIndex) }}</td>
					<td class="tableCell">{{ formatIsoDateString(dateToIsoStr(getDateFromIso8601String(occ.dateTimeStart), "DT", "-", ":", " "), "DMY", "-", true) }}</td>
					<td class="tableCell">{{ formatIsoDateString(dateToIsoStr(getDateFromIso8601String(occ.dateTimeEnd)), "DMY", "-", true) }}</td>
					<td class="tableCell">{{ getDaysUntilEnd(getDateFromIso8601String(occ.dateTimeStart)) }}</td>
					<td class="tableCell">{{ getDaysUntilEnd(getDateFromIso8601String(occ.dateTimeEnd)) }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "future",
	mixins: [globalSettings],
	components: {
	},
	data: function() {
		return {
			occurrences: [],
			events: [],
			dtpicker: {							// This is needed because the datetime picker requires a number (not a text) as value of firstDayOfWeek; similar - it requires "real" null value (if case), but not the "null" (string) value for label.
				firstDayOfWeek: 1,
				label: null,
				privateDateStr: ""
			},
			displayDateStr: "2020-01-25",
		}
	},
	created: function() {
		this.displayDateStr = this.dateToIsoStr(this.addDays(new Date(), 10), "D");
		this.dtpicker.privateDateStr = this.displayDateStr;
		this.getFuture();
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		onDtPickerInput: function(ev) {
			this.displayDateStr = this.dtpicker.privateDateStr;
			this.getFuture();
		},
		onDtPickerValidateClicked: function() {
			this.displayDateStr = this.dtpicker.privateDateStr;
			this.getFuture();
		},
		onDtPickerHidden: function() {
			// Purpose: If the dt picker was not closed by clicking the validate button, its value is reset to the job's date-time. So thet the job datetime is only updated when the validate button was clicked.
			// If the dt picker WAS closed by clicking the validate button, then the values are however already equel - see the onDtPickerValidateClicked handler.
			this.dtpicker.privateDateStr = this.displayDateStr;
		},
		getDaysUntilEnd: function(occDateTimeEnd) {
			return Math.floor(this.datesDiff(occDateTimeEnd, new Date()));
		},
		getParentEventName: function(occIndex) {
			var occ = this.occurrences[occIndex];
			var event = this.getParentEvent(occ.originalEventId);
			if (occIndex == 0) {
				return event.name;
			}

			if (occ.originalEventId != this.occurrences[occIndex - 1].originalEventId) {
				return event.name;
			}
			else {
				return "";
			}
		},
		getParentEvent: function(eventId) {
			return this.events.find(ev => ev.id == eventId);
		},
		getFuture: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetOccurencesForInterval", {
				method: "GET",
				searchParams: {
					userId: this.goptions.user.id,
					timeZoneOffset: this.goptions.timeZoneOffset,
					dateTimeStartStr: this.dateToIsoStr(this.addDays(new Date(), 1), "D") + " 00:00:00",
					dateTimeEndStr: this.displayDateStr + " 23:59:59"
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.showWait(false);
					this.occurrences = responseJson.occurences;
					this.events = responseJson.parentEvents;
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		}
	}
}
</script>

<style>
.tableHead {
	width: 1px;
}
.tableCell {
	white-space: nowrap;
}
</style>