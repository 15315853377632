<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Users</div>

		<div>
			<a href="#" v-on:click.prevent="doEdit(0)" class="button">New...</a>
			<div>{{ users.length }} users</div>
		</div>

		<table class="table is-bordered is-narrow is-hoverable">
			<thead>
				<tr style="background-color: #eeeeee">
					<th>User name</th>
					<th>First name</th>
					<th>Last name</th>
					<th>Active</th>
					<th>Admin</th>
					<th>Ops</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="user in users" v-bind:key="user.id">
					<td>{{ user.userName }}</td>
					<td>{{ user.firstName }}</td>
					<td>{{ user.lastName }}</td>
					<td v-bind:style="{'background-color': user.isActive ? '#99FF99' : '#FFCC99' }">{{ user.isActive ? "Yes" : "No" }}</td>
					<td>{{ user.isAdmin ? "Yes" : "No" }}</td>
					<td>
						<div class="actionImagesContainer">
							<img src="../assets/images/edit_20.png" alt="Edit" title="Edit" style="margin-right: 20px; cursor: pointer;" @click.prevent="doEdit(user.id)" />
							<img src="../assets/images/delete_20.png" alt="Delete" title="Delete" style="cursor: pointer;" @click.prevent="doDelete(user.id)" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>


		<ModalPopupFade columns="6" transDuration="0.5s" popTitle="Edit user" :visible="editFormVisible" @askClose="editCancel">
			<UserEditor :targetUser="editUserObj" @doCancel="editCancel" @doSave="editSave" />
		</ModalPopupFade>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import UserEditor from "@/components/UserEditor.vue";
import ModalPopupFade from "@/components/controls/ModalPopupFade.vue";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'users',
	mixins: [globalSettings],
	components: {
		ModalPopupFade,
		UserEditor
	},
	data: function() {
		return {
			users: [],
			editFormVisible: false,
			editUserObj: null
		}
	},
	created: function() {
		this.getUsers();
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		editCancel: function() {
			this.editFormVisible = false;
		},
		editSave: function(userObj) {
			this.$ky(this.apiUrl + "/UserEditSave", {
				method: "POST",
				searchParams: {
					userStr: JSON.stringify(userObj)
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert(responseJson.errorDescription);
					}
					this.getUsers();
					this.editFormVisible = false;
				})
				.catch(error => {
					alert("Error: " + error.message);
					this.editFormVisible = false;
				});
		},
		doEdit: function(userId) {
			this.$ky(this.apiUrl + "/GetUser", {
				method: "GET",
				searchParams: {
					userId: userId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.editUserObj = responseJson.user;
					this.editFormVisible = true;
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		doDelete: function(userId) {
			var targetUser = this.users.find(user => user.id == userId);
			if (!confirm("Sure you wannt to delete this user?\r\n" + targetUser.userName)) {
				return;
			}
			this.$ky(this.apiUrl + "/UserDelete", {
				method: "GET",
				searchParams: {
					userId: userId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert(responseJson.errorDescription);
					}
					this.getUsers();
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		getUsers: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetUsers", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.users = responseJson.users;
					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});

		}
	}
}
</script>

<style>

</style>