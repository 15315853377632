<template>
	<div>
		<div
			>Some past event resolutuions will become invalid due to the event modifications.
			<br/>Please select how do you prefer to manage this.
		</div>
		<div style="margin-top: 15px;">
			<select v-model="method">
				<option value="Fix">Fix</option>
				<option value="Clone">Clone</option>
			</select>
		</div>
		<div v-html="methodHint" style="margin-top: 5px;"></div>
		<div v-if="method == 'Fix'" style="margin-top: 5px;">
			<div>Set missing occurrences to</div>
			<div>
				<select v-model="fillWith">
					<option value="Done">Done</option>
					<option value="Dismissed">Dismissed</option>
					<option value="None">None (I will later mark the occurrences, manually, if needed)</option>
				</select>
			</div>
		</div>
		<div style="margin-top: 15px; text-align: center;">
			<a style="vertical-align: middle;" href="#" v-on:click.prevent="proceed()" class="button">Proceed</a>
			<a style="vertical-align: middle;" href="#" v-on:click.prevent="cancel()" class="button">Cancel event modification</a>
		</div>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: "FixCheckMarks",
	mixins: [globalSettings],
	props: {
			defaultMethod: {
				default: "Fix"
			},
			defaultFillWith: {
				default: "Done"
			}
		},
	data: function() {
		return {
			method: "",
			methodHint: "",
			fillWith: ""
		}
	},
	computed: {
	},
	watch: {
		"method": function() {
			switch(this.method) {
				case "Fix":
					this.methodHint = "This will: Remove orphan resolutions + Set any missing past occurrence to the resolution you specify below. <br/>If you're not sure, select this option.";
					break;
				case "Clone":
					this.methodHint = "This will: Clone the event + Any past occurrence without resolution will be set to Done + Set old event as historical";
					break;
			}
		}
	},
	mounted: function() {
		this.method = this.defaultMethod;
		this.fillWith = this.defaultFillWith;
	},
	methods: {
		cancel: function() {
			this.$emit("cancel");
		},
		proceed: function() {
			this.$emit("proceed", this.method, this.fillWith);
		}
	}
}
</script>

<style>

</style>