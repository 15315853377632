<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Database</div>
	</div>
</template>

<script>
export default {
	name: 'database',
	components: {
	}
}
</script>

<style>

</style>