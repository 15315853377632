<template>
	<div v-if="userObj != null">
		<div class="columns is-vcentered is-mobile" style="margin-top: 0px;">
			<div class="column is-narrow">Active</div>
			<div class="column is-narrow">
				<input type="checkbox" v-model="userObj.isActive" />
			</div>
		</div>
		<div class="columns is-vcentered is-mobile">
			<div class="column is-narrow">Admin</div>
			<div class="column is-narrow">
				<input type="checkbox" v-model="userObj.isAdmin" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">User name</div>
			<div class="column">
				<input type="text" size="25" v-model="userObj.userName" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">First name</div>
			<div class="column">
				<input type="text" size="25" v-model="userObj.firstName" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Last name</div>
			<div class="column">
				<input type="text" size="25" v-model="userObj.lastName" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Password</div>
			<div class="column">
				<input type="text" size="25" v-model="userObj.password" />
			</div>
		</div>

		<div class="columns is-vcentered" style="margin-top: 20px;">
			<div class="column" style="text-align: center;">
				<a href="#" class="button " @click.prevent="doSave">OK</a>
				<a href="#" class="button" @click.prevent="doCancel">Cancel</a>
			</div>
		</div>


	</div>
</template>

<script>
import ky from "ky";
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: "UserEditor",
	mixins: [globalSettings],
	props: {
			targetUser: {
				default: null
			}
		},
	data: function() {
		return {
			userObj: {}
		}
	},
	computed: {
	},
	watch: {
		"targetUser": function() {
			this.userObj = this.targetUser;
		}
	},
	methods: {
		doCancel: function() {
			this.$emit("doCancel");
		},
		doSave: function() {
			this.$emit("doSave", this.userObj);
		}
	}
}
</script>

<style>

</style>