<template>



		<!-- <div id="nav">
			<router-link to="/events">Events</router-link>|
			<router-link to="/">Dashboard</router-link>
		</div>
		<router-view /> -->
		<!-- =================== -->


        <div>
			<nav class="navbar is-primary" v-if="goptions.credentials.validLogin" role="navigation" aria-label="main navigation">
				<div class="navbar-brand">
					<!-- navbar items, navbar burger... -->
					<!-- <span class="navbar-item" style="font-size:20pt">Far Events</span> -->
					<span class="navbar-item" style="font-size:20pt; cursor: default;">
							<img src="@/assets/images/logo.png"/>
					</span>

					<a role="button" class="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false" @click.prevent="switchMobileMenuVisibility()" v-bind:class="{'is-active': mobileMenuVisible}">
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>
				<div class="navbar-menu" id="navMenu" v-bind:class="{'is-active': mobileMenuVisible}">
					<!-- navbar start, navbar end -->
					<div class="navbar-start">
					<!-- <div class="navbar-start" v-if="isLicensed && (serverPassword == userPassword)"> -->
						<span class="is-hidden-mobile" style="width: 40px;"></span>
						<router-link :to="{ name: 'events'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Events</router-link>
						<span style="width: 40px;"></span>
						<router-link :to="{ name: 'dashboard'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Dashboard</router-link>
						<router-link :to="{ name: 'history'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">History</router-link>
						<router-link :to="{ name: 'future'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Future</router-link>
						<span style="width: 40px;"></span>
						<router-link v-if="goptions.user.isAdmin" :to="{ name: 'users'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Users</router-link>
						<router-link v-if="goptions.user.isAdmin" :to="{ name: 'serverSubscriptions'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Server subscriptions</router-link>
						<router-link :to="{ name: 'settings'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Settings</router-link>
						<router-link :to="{ name: 'about'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">About</router-link>
						<span style="width: 40px;"></span>
						<router-link :to="{ name: 'login', params: { signOut: true }}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Sign out</router-link>
						<div v-if="goptions.user.isAdmin" class="navbar-item">
							<span style="width: 40px;"></span>
							<router-link :to="{ name: 'database'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Database</router-link>
							<router-link :to="{ name: 'test'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Test</router-link>
							<router-link :to="{ name: 'testRegistration'}" class="mainMenu-item-link navbar-item" @click.native="switchMobileMenuVisibility()">Test Reg.</router-link>
						</div>
					</div>
				</div>
			</nav>

        </div>

        <!--Main area panel-->
        <div id="mainAreaDiv" class="mainAreaDiv" style="height: 94%">
            <!-- Main area DIV -->
            <router-view />
        </div>

		<ModalPopupFade columns="2" transDuration="0s" popTitle="" backcolor="#00000000" :visible="goptions.waitPopupVisible" :keepLayoutOnMobile="true">
			<div style="text-align: center; font-weight: bold;">Wait...</div>
		</ModalPopupFade>


</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import ModalPopupFade from "@/components/controls/ModalPopupFade.vue";
import { pushMessageHelper } from "@/mixins/pushMessageHelper.js"

export default {
	name: "app",
	mixins: [pushMessageHelper],
	data: function() {
		return {
			mobileMenuVisible: false
		}
	},
	components: {
		ModalPopupFade
	},
	created: function() {
		// [PWA]
		// Service worker registration
		// For development purposes, consider localhost
		if ('serviceWorker' in navigator) {

				window.addEventListener('load', () => {
					var serviceWorkerFile = (process.env.NODE_ENV != 'development' ? process.env.BASE_URL + "/sw.js" : "service-worker-events.js");
					console.log("serviceWorkerFile: " + serviceWorkerFile);
					navigator.serviceWorker.register(serviceWorkerFile)
						.then(registration => {
							console.log('Service Worker is registered', registration);
						})
						.catch(err => {
							console.error('Registration failed:', err);
						});
				});
				// For later use - in order to install the PWA as Desktop app from within the application itself
				// NOTE: in case the application is already installed, the event (or deferredPrompt that stores it, respectively) is "undefined".
				// Meaning that, if this value is undefined, the application shiuld not offer the installation option/related controls etc.
				/*
				window.addEventListener('beforeinstallprompt', (e) => {
					// Prevent Chrome 67 and earlier from automatically showing the prompt
					e.preventDefault();
					// Stash the event so it can be triggered later.
					deferredPrompt = e;
				});
				*/
		}
		else {
			console.log("Service worker support not available");
		}





/*
		if (this.isProductionEnvironment) {
			if ('serviceWorker' in navigator) {
				// Register the service worker
				window.addEventListener('load', () => {
					//navigator.serviceWorker.register("./" + (this.isProductionEnvironment ? "ui/dist/" : "dist/" ) + "service-worker.js")
					navigator.serviceWorker.register(process.env.BASE_URL + "/sw.js")
						.then(registration => {
							console.log('Service Worker is registered', registration);
						})
						.catch(err => {
							console.error('Registration failed:', err);
						});
				});

				// For later use - in order to install the PWA as Desktop app from within the application itself
				// NOTE: in case the application is already installed, the event (or deferredPrompt that stores it, respectively) is "undefined".
				// Meaning that, if this value is undefined, the application shiuld not offer the installation option/related controls etc.
				window.addEventListener('beforeinstallprompt', (e) => {
					// Prevent Chrome 67 and earlier from automatically showing the prompt
					e.preventDefault();
					// Stash the event so it can be triggered later.
					deferredPrompt = e;
				});
			}
		}
		else {		// Development purposes, considering localhost
			console.log("Registering service worker...");
			//return Promise.reject("Service worker registration not implemented");
			if (!("serviceWorker" in navigator)) {
				console.log("Service worker support not available");
			}

			navigator.serviceWorker.register("service-worker-events.js")
			.then(regObj => {
				console.log("Service worker is registered");
			});
		}
*/



/*
			// [PWA]
			// Service worker registration
			// For development purposes, consider localhost
			console.log("Registering service worker...");
			//return Promise.reject("Service worker registration not implemented");
			if (!("serviceWorker" in navigator)) {
				console.log("Service worker support not available");
			}

			navigator.serviceWorker.register("service-worker-events.js")
			.then(regObj => {
				console.log("Service worker is registered");
			});
*/




		// Add event listener window active
		window.addEventListener("focus", this.onWindowActive);

		// Add event listener for messages from the service worker
		navigator.serviceWorker.addEventListener('message', event => {
			console.log("======> event.data", event.data);
		});
		
		// Navigation guards
		this.$router.beforeEach((to, from, next) => {
			// [PWA]
			// If no connection => Display specific "no connection" page.
			// NOTE: The "no connection" page needs to be pre-cached, and specific code needs to be
			// added in the service worker in oeder to serve from cache if the default fetch fails.
			if (!navigator.onLine) {
				window.location = "./noConnection.html";
				return;
			}

			if (!this.goptions.credentials.validLogin) {
				if (to.name != "login") {
					next({ name: "login" });
					return;
				}
			}
			next();
		})

		// Go to login if not already there.
		if (this.$route.name != "login") {
			this.$router.push({ name: "login" });
		}
	},
	destroyed: function() {
		window.removeEventListener("focus", this.onWindowActive);
	},
	mounted: function() {
		// Store globally the notifications permissoion
		this.goptions.notificationsState = this.getNotificationPermission();
	},
	computed: {
		...mapState([
			"goptions"
		])
		//,
		// Other computed props here
	},
	methods: {
		switchMobileMenuVisibility: function() {
			//alert("Burger clicked");
			this.mobileMenuVisible = !this.mobileMenuVisible;
		},
		onWindowActive: function() {
			// Play sound
			if (!this.goptions.credentials.validLogin) {
				if (this.$router.currentRoute.name != "login") {
					this.$router.push({ name: "login" });
				}
			}
			// const sound = new Audio(require("./assets/sounds/PositiveDame.mp3"));
			// sound.play();
		}
	}
}
</script>

<style>
#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
