<template>
	<div class="full-height-width" style="border: 0px solid red;">
		<div class="grid-button-row">
			<div class="grid-button-cell">
				<input type="text" v-model="inputUserName" placeholder="(nume)" style="width: 50%; font-size: 25px;">
			</div>
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(1)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(2)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(3)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(4)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(5)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(6)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(7)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(8)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(9)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-button-row">
			<div class="grid-button-cell2" @click.prevent="checkUserNamePassword(true)" style="max-width: 200px; cursor: pointer; border: 1px black solid;">OK</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { globalSettings } from "@/mixins/globalSettings";
import Vue from "vue";

export default {
	name: "LoginUserNamePassword",
	mixins: [globalSettings],
	data: function() {
		return {
			inputPassword: "",
			inputUserName: ""
		}
	},
	created: function() {
		this.goptions.credentials.validLogin = false;

		if (this.$route.params["signOut"]) {
			localStorage.password = "";
		}

		var localCredentials = this.getLocalCredentials();

		this.inputUserName = localCredentials.userName;
		this.inputPassword = localCredentials.password;

		this.checkUserNamePassword(false);
	},
	mounted: function() {
		if (localStorage.userName != "") {
			this.inputUserName = localStorage.userName;
		}
	},
	watch: {
		"inputUserName": function() {
			this.inputPassword = "";
		}
	},
	computed: {
		...mapState([
			"goptions"
		])
		//,
		// Other computed props here
	},
	methods: {
		...mapMutations([
				"GLOBAL_StoreUserPassword", "GLOBAL_StoreServerPassword", "GLOBAL_StoreUserName"
			]),
		getLocalCredentials: function() {
			var userName = localStorage.hasOwnProperty("userName") ? localStorage.userName : null;
			var password = localStorage.hasOwnProperty("password") ? localStorage.password : null;
			return {userName: userName, password: password};
		},
		randomColor: function() {
			return "rgb(" + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + ")";
		},
		onClick: function(cellId) {
			this.inputPassword += cellId;
			if (this.inputPassword.length > 50) {
				this.inputPassword = "";
			}
		},
		onDoubleClick: function() {
			this.inputPassword = "";
		},
		checkUserNamePassword: function(showAlert) {
			localStorage.userName = this.inputUserName;
			localStorage.password = this.inputPassword;

			this.$ky(this.apiUrl + "/CheckUserPassword", {
				method: "GET",
				searchParams: {
					user: this.inputUserName,
					password: this.inputPassword
				},
				cache: "no-cache",
				timeout: 5000,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				//this.GLOBAL_StoreServerPassword(responseJson.p);
				if (responseJson.valid) {
					this.goptions.user = responseJson.user;
					this.goptions.timeZoneOffset = this.getTimeZoneOffset();
					this.goptions.credentials.validLogin = true;
					this.$router.push({ name: "dashboard" });
				}
				else {
					if (showAlert) {
						alert("Incorect");
					}
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		}
	}
}
</script>

<style>
		.full-height-width {
			position: absolute;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: stretch;
			left: 0px;
			right: 0px;
			top: 0px;
			bottom: 0px;
		}

		.grid-row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: stretch;
			flex: 1;
			background-color: white;
		}

		.grid-cell {
			flex: 1;
			margin: 2px;
			text-align: center;
			line-height: 75px;
			font-size: 30px;
			background-color: #c02222;
		}

		.grid-button-row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			flex: 0.5;
			background-color: lightgrey;
			margin: 0px;
		}

		.grid-button-cell {
			flex: 3;
			text-align: center;
			align-items: center;
			/* line-height: 100px; */
			font-size: 30px;
			background-color: lightgrey;
		}

		.grid-button-cell2 {
			flex: 3;
			text-align: center;
			align-items: center;
			/* line-height: 100px; */
			font-size: 30px;
			background-color: #999999;
			border-radius: 10px;
		}

		div .grid-button-cell2:hover {
			background-color: white;
		}
</style>
