<template>
	<div v-if="eventObj != null">
		<div class="columns is-vcentered" style="margin-top: 0px;">
			<div class="column is-narrow">Active</div>
			<div class="column is-narrow">
				<input type="checkbox" id="chkIsActive" v-model="eventObj.isActive" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Notify before (days)</div>
			<div class="column">
				<input type="text" id="txtNotifyBefore" size="4" v-model.number="eventObj.notifyBeforeDays" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Due date</div>
			<div class="column is-hidden-mobile is-narrow">
				<VueCtkDateTimePicker
					v-model="dtpicker.privateDateTimStr" 
					:first-day-of-week="dtpicker.firstDayOfWeek" 
					:label="dtpicker.label" 
					input-size="sm" 
					no-label
					color="green"
					noClearButton
					no-button-now
					format="YYYY-MM-DD HH:mm:ss"
					formatted="YYYY-MM-DD HH:mm"
					@validate="onDtPickerValidateClicked()"
					@is-hidden="onDtPickerHidden()"
					>
				</VueCtkDateTimePicker>
			</div>
			<div class="column is-hidden-tablet is-narrow">
				<VueCtkDateTimePicker
					v-model="dtpicker.privateDateTimStr" 
					:first-day-of-week="dtpicker.firstDayOfWeek" 
					:label="dtpicker.label" 
					input-size="sm" 
					no-label
					inline
					color="green"
					noClearButton
					:no-keyboard="true"
					format="YYYY-MM-DD HH:mm:ss"
					formatted="YYYY-MM-DD HH:mm"
					@validate="onDtPickerValidateClicked()"
					@input="onDtPickerInput"
					>
				</VueCtkDateTimePicker>
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Name</div>
			<div class="column">
				<input type="text" id="txtOccurenceName" size="25" v-model="eventObj.name" />
			</div>
		</div>
		<div class="columns is-vcentered">
			<div class="column is-narrow">Description</div>
			<div class="column">
				<input type="text" id="txtOccurenceDescription" size="25" v-model="eventObj.description" />
			</div>
		</div>

		<div v-bind:id="setSliderContainerId()" ref="sliderContainer"></div>
		<div>
			<div class="daysBeforeBullet" style="background-color: #88FF88;">{{ thumbValues[0] }}</div>
			<div class="daysBeforeBullet" style="background-color: #FFFF88;">{{ thumbValues[1] }}</div>
			<div class="daysBeforeBullet" style="background-color: #FF8888;">{{ thumbValues[2] }}</div>
		</div>

		<div>
			<table class="is-narrow">
				<tr>
					<td>Recurrence type</td>
					<td>Recurrence value</td>
					<td>Recurrence unit</td>
				</tr>
				<tr>
					<td>
						<select id="comboRecurrenceType" v-model="eventObj.recurrenceType">
							<option v-for="recurrType in enumPresenter.recurrenceTypes" v-bind:value="recurrType.value" :key="recurrType.value">{{ recurrType.name }}</option>
						</select>
					</td>
					<td>
						<input type="text" v-if="[1,6,7].includes(eventObj.recurrenceType)" id="txtRecurrenceValue" size="4" v-model.number="eventObj.recurrenceValue" />
					</td>
					<td>
						<select v-if="eventObj.recurrenceType == 1" id="comboRecurrenceType" v-model="eventObj.recurrenceUnit">
							<option v-for="recurrUnit in enumPresenter.recurrenceUnit" v-bind:value="recurrUnit.value" :key="recurrUnit.value">{{ recurrUnit.name }}</option>
						</select>
					</td>
				</tr>
			</table>
		</div>
		
		<div class="columns is-vcentered" style="margin-top: 20px;">
			<div class="column" style="text-align: center;">
				<a href="#" class="button " @click.prevent="doSave">OK</a>
				<a href="#" class="button" @click.prevent="doCancel">Cancel</a>
			</div>
		</div>


	</div>
</template>

<script>
import * as d3 from "d3";
import sslider from "@/mixins/sslider";
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: "EventEditor",
	mixins: [sslider,  globalSettings],
	props: {
			eventId: {
				default: 0
			},
			targetEvent: {
				default: null
			}
		},
	data: function() {
		return {
			sliderContainerId: "",
			sliderId: "",
			sliderObj: null,
			eventObj: {},
			dtpicker: {							// This is needed because the datetime picker requires a number (not a text) as value of firstDayOfWeek; similar - it requires "real" null value (if case), but not the "null" (string) value for label.
				firstDayOfWeek: 1,
				label: null,
				privateDateTimStr: ""
			}
		}
	},
	computed: {
		notifyBeforeDays: function() {
			return this.eventObj.notifyBeforeDays;
		},
		thumbValues: function() {
			if (this.eventObj.reminders == undefined) {
				return [1, 1, 1];	// Just tu have something until the first event object is loaded
			}
			return this.eventObj.reminders.map(reminder => reminder.daysBeforeEnd);
		}
	},
	watch: {
		"targetEvent": function() {
			this.eventObj = this.targetEvent;
			if (this.sliderObj != null) {
				this.removeSlider();
			}
			this.createSlider();
			this.dtpicker.privateDateTimStr = this.eventObj.dueDateTimeStr;
		},
		"notifyBeforeDays": function() {
			// Changed the slider range => Reset the slider range value.
			this.sliderObj.range(this.eventObj.notifyBeforeDays, 0);

			this.updateEventObjectThumbValues();
		}
	},
	beforeUnmount: function () {
		if (this.sliderObj != null) {
			this.removeSlider();
		}
	},
	methods: {
		onDtPickerInput: function() {
			this.eventObj.dueDateTimeStr = this.dtpicker.privateDateTimStr;
		},
		doCancel: function() {
			console.log(this.eventObj);
			this.$emit("doCancel");
		},
		doSave: function() {
			// Set the event object reminders "daysBeforeEnd" values to the ones in the slider.
			var thumbsData = this.sliderObj.get("thumbsData");
			for (var i = 0; i < thumbsData.length; i++) {
				this.eventObj.reminders[i].daysBeforeEnd = thumbsData[i].value;
			}
			this.$emit("doSave", this.eventObj);
		},
		onDtPickerValidateClicked: function() {
			// This is where we update the job date-time
			this.eventObj.dueDateTimeStr = this.dtpicker.privateDateTimStr;
		},
		onDtPickerHidden: function() {
			// Purpose: If the dt picker was not closed by clicking the validate button, its value is reset to the job's date-time. So thet the job datetime is only updated when the validate button was clicked.
			// If the dt picker WAS closed by clicking the validate button, then the values are however already equel - see the onDtPickerValidateClicked handler.
			this.dtpicker.privateDateTimStr = this.eventObj.dueDateTimeStr;
		},
		setSliderContainerId: function() {
			this.sliderContainerId = "sliderContainer_" + Date.now();	// Date.now() ensures distinct id
			this.sliderId = "svgSlider_"  + Date.now();
			return this.sliderContainerId;
		},
		onSliderDragEnd: function(d) {
			this.updateEventObjectThumbValues();
		},
		updateEventObjectThumbValues: function() {
			var values = this.sliderObj.get("values");
			for (var i = 0; i < values.length; i++) {
				this.eventObj.reminders[i].daysBeforeEnd = values[i];
			}
		},
		createSlider: function() {
			var slider = new this.sslider();
			// console.log(this.eventObj);
			// console.log("this.eventObj.notifyBeforeDays = " + this.eventObj.notifyBeforeDays);
			// console.log("this.sliderContainerId = " + this.sliderContainerId);

			
			var sliderWidth = this.$refs.sliderContainer.clientWidth - this.dirtyFixes.sliderWidthFix;

			slider.create(this.sliderContainerId, this.sliderId, sliderWidth)
				.range(this.eventObj.notifyBeforeDays, 0)
				.thumbs([this.eventObj.reminders[0].daysBeforeEnd, this.eventObj.reminders[1].daysBeforeEnd, this.eventObj.reminders[2].daysBeforeEnd])
				.overlap(false, true)
				.step(1);

			slider.events.onDrag = function (d) {
			};

			slider.events.onDragStart = function (d) {
				//console.log("[" + d.index + "] X coord = " + d.x);
			};

			slider.events.onDrag = this.onSliderDragEnd;

			this.sliderObj = slider;
			console.log("Sliter control created");
		},
		removeSlider: function() {
			this.sliderObj.removeSvg();
			console.log("Sliter control removed");
		},
		setSliderValue: function (thumbIndex, val) {
			this.sliderObj.setThumbValue(thumbIndex, val);
			// Return the real the value according to the thumb current value.
			// Reason: the initial value could be outside the slider range => the thumb value is corrected internally by the slider object in order to fit in the slider range.
			return this.sliderObj.getThumbValue(thumbIndex);
		}
	}
}
</script>

<style>
	.sliderTrack {
		rx: 5px;
		ry: 5px;
		stroke-width: 1px;
		stroke: #555555;
		fill: #00ffff;
	}

	.sliderThumb {
		stroke-width: 2px;
		fill: #88ff88;
		fill-opacity: 0.75;
		rx: 6px;
		ry: 6px;
	}

	.thumbActive {
		fill-opacity: 1;
		fill:yellow
	}

	.daysBeforeBullet {
		width: 40px;
		height: 30px;
		border: 1px solid black;
		border-radius: 10px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>