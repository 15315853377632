<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Events</div>
		<div>
			<a style="vertical-align: middle;" href="#" v-on:click.prevent="doEdit(0)" class="button">New...</a>

			<span style="margin-left: 20px;">Filter</span>
			<select style="margin-left: 10px; vertical-align: middle;" id="comboRecurrenceType" v-model="goptions.eventFilter">
				<option v-for="filter in enumPresenter.eventFilters" v-bind:value="filter.value" :key="filter.value">{{ filter.name }}</option>
			</select>

			<!-- <div>{{ farEvents.length }} events</div> -->
		</div>

		<div style="display: inline-block; height: 10px;"></div>

		<table class="table is-bordered is-narrow is-hoverable">
			<thead>
				<tr style="background-color: #eeeeee">
					<th>Active</th>
					<th>Name</th>
					<th>Beginning with</th>
					<th>Notify before<br/>(days)</th>
					<th>Recurrence<br/>type</th>
					<th>Recurrence<br/>value</th>
					<th>Recurrence<br/>unit</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="evt in filteredEvents" v-bind:key="evt.id">
					<td v-bind:style="{'background-color': evt.isActive ? '#99FF99' : '#FFCC99' }">{{ evt.isActive ? "Yes" : "No" }}</td>
					<td>{{ evt.name }}</td>
					<td>{{ formatIsoDateString(evt.dueDateTimeStr, "DMY", "-", true) }}</td>
					<td>{{ evt.notifyBeforeDays }}</td>
					<td>{{ enumPresenter.getName(enumPresenter.recurrenceTypes, evt.recurrenceType) }}</td>
					<td>{{ [1,6,7].includes(evt.recurrenceType) ? evt.recurrenceValue : ""  }}</td>
					<td>{{ evt.recurrenceType == 1 ? enumPresenter.getName(enumPresenter.recurrenceUnit, evt.recurrenceUnit) : "" }}</td>
					<td>
						<div class="actionImagesContainer" style="width:100px;">
							<img src="../assets/images/edit_20.png" alt="Edit" title="Edit" style="margin-right: 20px; cursor: pointer;" @click.prevent="doEdit(evt.id)" />
							<img src="../assets/images/delete_20.png" alt="Delete" title="Delete" style="margin-right: 20px; cursor: pointer;" @click.prevent="doDelete(evt.id)" />
							<img src="../assets/images/rainbow.png" alt="Next occurrence" title="Next occurrence" style="cursor: pointer;" @click.prevent="showNextOccurrence(evt.id)" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<ModalPopupFade columns="6" transDuration="0.5s" popTitle="Edit event" :visible="editFormVisible" @askClose="editCancel">
			<EventEditor :targetEvent="editEventObj" @doCancel="editCancel" @doSave="editValidateAndSave" />
		</ModalPopupFade>

		<ModalPopupFade columns="6" transDuration="0.5s" popTitle="Manage past resolutions" :visible="eventCheckmarksFixVisible" @askClose="marksFixCancel">
			<FixCheckMarks defaultMethod="Fix" @proceed="marksFixProceed" @cancel="marksFixCancel" />
		</ModalPopupFade>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import EventEditor from "@/components/EventEditor.vue";
import FixCheckMarks from "@/components/FixCheckMarks.vue"
import ModalPopupFade from "@/components/controls/ModalPopupFade.vue";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'events',
	mixins: [globalSettings],
	components: {
		ModalPopupFade,
		EventEditor,
		FixCheckMarks
	},
	data: function() {
		return {
			farEvents: [],
			filterOnlyActive: false,
			editFormVisible: false,
			editEventObj: null,
			eventCheckmarksFixVisible: false,
			checkMarkFix : {
				value: "newEventNoHistory"
			}
		}
	},
	created: function() {
		this.getEvents();
	},
	computed: {
		...mapState([
			"goptions"
		]),
		filteredEvents: function() {
			switch(this.goptions.eventFilter) {
				case 1:
					return this.farEvents.filter(ev => ev.isActive);
					break;
				case 2:
					return this.farEvents.filter(ev => !ev.isActive);
					break;
				case 3:
					return this.farEvents;
					break;
			}
		}
	},
	watch: {
	},
	methods: {
		marksFixCancel: function() {
			this.eventCheckmarksFixVisible = false;
		},
		marksFixProceed: function(method, fillResolution) {
			this.saveEvent(this.editEventObj, method, fillResolution);
			this.eventCheckmarksFixVisible = false;
		},
		editCancel: function() {
			this.editFormVisible = false;
		},
		editValidateAndSave: function(eventObj) {
			// If existing event => Check if there are checkmarks that don't fit in the occurrences.
			if (eventObj.id > 0) {
				this.$ky(this.apiUrl + "/GetOrphanCheckmarksForModifiedEvent", {
					method: "GET",
					searchParams: {
						eventId: eventObj.id,
						newDueDateTimeStr: eventObj.dueDateTimeStr,
						newNotifyBeforeDays: eventObj.notifyBeforeDays,
						newRecurrenceType: eventObj.recurrenceType,
						newRecurrenceValue: eventObj.recurrenceValue,
						newRecurrenceUnit: eventObj.recurrenceUnit
					},
					cache: "no-cache",
					timeout: 3000,
					retry: 1
					})
					.json()
					.then(responseJson => {
						if (!responseJson.success) {
							return;
						}
						if (responseJson.checkMarks.length > 0) {
							console.log(responseJson.checkMarks);
							this.editFormVisible = false;
							this.eventCheckmarksFixVisible = true;
							return;
						}
						// No orphan checkmarks => Go on with Save.
						this.saveEvent(eventObj, "None");
					})
					.catch(error => {
						alert("Error: " + error.message);
						this.editFormVisible = false;
					});
			}
			else {
				this.saveEvent(eventObj, "None");
			}
		},
		saveEvent: function(eventObj, orphanCheckMarksAction, fillWith) {
			console.log("Saving: ");
			console.log(eventObj);
			this.$ky(this.apiUrl + "/SaveEvent", {
				method: "POST",
				searchParams: {
					eventObjectStr: JSON.stringify(eventObj),
					orphanCheckMarksAction: orphanCheckMarksAction,
					fillResolution: fillWith
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert(responseJson.errorDescription);
					}
					this.getEvents();
					this.editFormVisible = false;
				})
				.catch(error => {
					alert("Error: " + error.message);
					this.editFormVisible = false;
				});
		},
		getEvents: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetEvents", {
				method: "GET",
				searchParams: {
					userId: this.goptions.user.id
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.farEvents = responseJson.events;
					this.filterOnlyActive = responseJson.filterOnlyActive;
					this.showWait(false);
			})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});

		},
		doEdit: function(eventId) {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetEvent", {
				method: "GET",
				searchParams: {
					eventId: eventId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.editEventObj = responseJson;
					if (eventId == 0) {	// new event => no user assigned by defaukt => assign current user
						this.editEventObj.userId = this.goptions.user.id;
					}
					this.editFormVisible = true;
					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		},
		doDelete: function(eventId) {
			var targetEvent = this.farEvents.find(ev => ev.id == eventId);
			if (!confirm("Sure you wannt to delete this event?\r\n" + targetEvent.name)) {
				return;
			}
			this.$ky(this.apiUrl + "/EventDelete", {
				method: "GET",
				searchParams: {
					eventId: eventId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert("Error on delete");
					}
					this.getEvents();
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		showNextOccurrence: function(eventId) {
			this.$ky(this.apiUrl + "/GetNextOccurrenceFromNow", {
				method: "GET",
				searchParams: {
					eventId: eventId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					var nextOccurrence = responseJson.nextOccurrence;
					if (nextOccurrence == null) {
						alert("No next occurrence found");
					}
					else {
						alert(this.formatIsoDateString(this.dateToIsoStr(this.getDateFromIso8601String(nextOccurrence.dateTimeEnd)), "DMY", "-", true ));
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		}
	}
}
</script>

<style>
	div.modalContainer {
		position: absolute;
		text-align: center;
		left: 0px; top: 0px; right: 0px; bottom: 0px;
		background-color: #000000;
		opacity: 1;
		z-index: 999;
		overflow-y: auto;
	}

	div.editForm {
		display: inline-block;
		margin-top: 30px;
		margin-bottom: 30px;
		top: 20%;
		border: 2px solid red;
		opacity: 1;
		background-color: white;
		color: red;
	}
</style>