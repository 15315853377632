<template>
	<div class="box is-size-4 has-text-centered">Version: {{ version }}</div>
</template>

<script>
import packageJson from "../../package.json";

export default {
	name: "about",
	data: function() {
		return {
		}
	},
	computed: {
		version: function() {
			return packageJson.version;
		}
	}
}
</script>