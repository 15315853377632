import { mapState, mapMutations, Store } from 'vuex'

export const pushMessageHelper = {
	data() {
		return {
			appServerPrivateKey: "x9Gcqme9MYcWLiedQg2DbHCUCOv5pjMejD5UOQSBa5o",
			appServerPublicKey: "BL8w0y9ArGLR_WruH7gyU12oTFzRNFdWET2Zcbmz7x7ZC5IBBMkw53CtGe4h8EuJwchYq2V5q0faHIbH4bwMuI4"
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
	},
	methods: {
		checkNotificationSupport() {
			if (!("Notification" in window)) {
				return Promise.reject("The browser doesn't support notifications");
			}
			console.log("The browser supports notifications.");
			return Promise.resolve("OK");
		},
		getNotificationPermission() {
			return window.Notification.permission;
		},
		checkNotificationPermission() {
			this.goptions.notificationsEnabled = (window.Notification.permission == "granted");
		},
		requestNotificationPremissions() {
			return Notification.requestPermission(status => {
				this.goptions.notificationsEnabled = (status == "granted");
				this.goptions.notificationsState = status;
			})
		},
		showSimpleNotification(title) {
			navigator.serviceWorker.getRegistration()
			.then(registration => registration.showNotification(title));
		},
		showComplexNotification(title, options) {
			navigator.serviceWorker.getRegistration()
			.then(registration => registration.showNotification(title, options));
		},
		urlBase64ToUint8Array(base64String) {
			const padding = '='.repeat((4 - base64String.length % 4) % 4);
			const base64 = (base64String + padding)
			  .replace(/-/g, '+')
			  .replace(/_/g, '/');
		  
			const rawData = window.atob(base64);
			const outputArray = new Uint8Array(rawData.length);
		  
			for (let i = 0; i < rawData.length; ++i) {
			  outputArray[i] = rawData.charCodeAt(i);
			}
			return outputArray;
		},
		async subscribeToNotifications() {
			var registration = await navigator.serviceWorker.getRegistration();
			if (registration == undefined) {
				return null;
			}

			var registration = await registration.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: this.urlBase64ToUint8Array(this.appServerPublicKey)});

			return registration;
			/*
			navigator.serviceWorker.getRegistration()
			.then(registration => {
				registration.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: this.urlBase64ToUint8Array(this.appServerPublicKey)})
				.then(function(subscription) {
					console.log("subscription", subscription.toJSON());
				})
			})
			*/
		},
		async getSubscription() {
			// Returns the current subcription object, or null if no subscription.

			var registration = await navigator.serviceWorker.getRegistration();
			var subscription = await registration.pushManager.getSubscription();

			return subscription;
		},
		async removeSubscription() {
			var subscription = await this.getSubscription();
			if (subscription == null) {
				return false;
			}
			try {
				var result = await subscription.unsubscribe();
				return result;
			}
			catch(ex) {
				alert(ex);
				return false;
			}

			/*
			this.getSubscription().then(subscription => {
				if (subscription != null) {	
					subscription.unsubscribe()
					.then(success => {
						console.log("Unsubscribe OK")
						return true;
					})
					.catch(ex => {
						console.log("Unsubscribe failed")
						return false;
					})
				}
				else {
					alert("No current subscription");
				}
			})
			*/
		}
	}
}