<template>
	<div ref="modalContainer" class="overlay" style="overflow-y: auto;" v-bind:style="{'visibility': cssVisibility, 'opacity': opacity, 'transition': transDuration, 'background-color': backcolor}">
		<div ref="topObject" style="display: hidden;"></div>

		<!-- Title -->
		<!-- <div class="pop-title center-v">{{ popTitle }}</div> -->

		<!-- Button to close the overlay navigation -->
		<!-- <a href="javascript:void(0)" class="close" v-on:click.prevent="doClose()"></a> -->

		<!-- Overlay content -->
		<div class="overlay-content" style="height: 100%; position: absolute; top: 0px;">
			<div :class="{ 'is-mobile' : keepLayoutOnMobile }" class="columns is-centered is-vcentered" style="height: 100%;">
				<div class="column" :class="[widthColumns]" style="vertical-align: middle; background-color: white; border: 1px solid grey; border-radius: 10px; margin-left: 5%; margin-right: 5%;">
					<div v-if="popTitle || popTitle.length != 0" class="section-header" style="margin-bottom:20px">{{ popTitle }}</div>
					<slot style="vertical-align: middle;"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalPopupFade",
	data: function() {
		return {
			closedOffset: "",
			openOffset: "",
			opacity: 0,
			cssVisibility: "hidden",
			unregisterRouterGuard: null
		};
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		transDuration: {
			type: String,
			default: "0.5s"
		},
		backcolor: {
			type: String,
			default: "#000000bb"		// rgb(250, 255, 230)
		},
		popTitle: {
			type: String,
			default: ""		// rgb(250, 255, 230)
		},
		columns: {
			// type: Number,
			default: 1
		},
		keepLayoutOnMobile: {	// This is mainly for small popups like the "Wait.." displayed between when navigating between views.
			default: false
		}
	},
	watch: {
		visible: function() {
			if (this.visible) {
				// Scroll to top.
				// Reason: if the content is big (higher than the window height), and if the
				// user scrolls down the content, when the popup is displayed next time the
				// content would appear initially scrolled - not nice.
				this.$refs.topObject.scrollIntoView();
				this.cssVisibility = "visible";
				this.$refs.modalContainer.style.zIndex="9999";
				this.opacity = 1;


				// Back closes the modal popup instead of navigating back.
				this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.visible) {
						this.doClose();
						next(false);
					}
				});
			}
			else {
				this.opacity = 0;
				this.cssVisibility = "hidden";

				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterRouterGuard();
			}
		}
	},
	computed: {
		widthColumns: function() {
			return "is-" + this.columns;
		}
	},
	created: function() {
		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
 	methods: {
		onKeydown: function(event) {
			// Manage the Esc key down
			if (event.keyCode == 27) {
				// Close the modal popup
				this.doClose();
			}
		},
		doClose: function() {
			this.$emit("askClose", {});
		}
	}
}
</script>

<style>
  /*=============== Modal - fullscreen ==================*/
  /* The Overlay (background) */
  .overlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:9999;
	opacity: 0;
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	background-color: rgb(255,0,0); /* Black fallback color */
	/*background-color: rgba(0,0,0, 0.9);*/ /* Black w/opacity */
	overflow-x: hidden; /* Disable horizontal scroll */
	transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  
  /* Position the content inside the overlay */
  .overlay-content {
	position: relative;
	top: 0px; /* 25% from the top */
	width: 100%; /* 100% width */
	/*text-align: center;*/ /* Centered text/links */
	margin-top: 0px; /* 30px top margin to avoid conflict with the close button on smaller screens */
	/* color: red; */
	/*font-size: 5mm;*/
  }
  
  /* When you mouse over the navigation links, change their color */
 /*
  .overlay a:hover, .overlay a:focus {
	color: #f1f1f1;
  }

  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
 /* 
  @media screen and (max-height: 450px) {
	.overlay a {font-size: 20px}
	.overlay .closebtn {
	  font-size: 40px;
	  top: 15px;
	  right: 35px;
	}
  }

  @media screen and (max-width: 400px) {
	.overlay-content {
		font-size: 10mm;
	}
}
*/
.overlay .pop-title {
	height: 60px;
	font-size: 20px;
	font-weight: bold;
	padding: 10px;
	background-color: #dddddd;
	border: 1px solid #555555;
	border-radius: 10px;
}

.center-v {
	display: flex;
	align-items: center;
}

.center-h {
	display: flex;
	justify-content: center;
}

/* Cllose button */
/* https://codepen.io/brissmyr/pen/egidw */
.overlay .close {
	position: absolute;
	right: 15px;
	top: 15px;
	width: 0px;
	height: 0px;
	opacity: 0.5;
  }

.overlay .close:hover {
	opacity: 1;
  }

.overlay .close:before, .close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 0px;
	width: 2px;
	background-color: #000;
  }
 
.overlay .close:before {
	transform: rotate(45deg);
  }

.overlay .close:after {
	transform: rotate(-45deg);
  }
</style>