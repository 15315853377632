<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Past unmanaged events</div>

		<table class="table is-bordered is-narrow is-hoverable">
			<thead>
				<tr style="background-color: #eeeeee">
					<th class="tableHead">Event</th>
					<th class="tableHead">Occurrence Start</th>
					<th class="tableHead">Occurrence End</th>
					<th class="tableHead">Exceeded days</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(occ, occIndex) in occurrences">
					<td class="tableCell">{{ getParentEventName(occIndex) }}</td>
					<td class="tableCell">{{ formatIsoDateString(dateToIsoStr(getDateFromIso8601String(occ.dateTimeStart), "DT", "-", ":", " "), "DMY", "-", true) }}</td>
					<td class="tableCell">{{ formatIsoDateString(dateToIsoStr(getDateFromIso8601String(occ.dateTimeEnd)), "DMY", "-", true) }}</td>
					<td class="tableCell">{{ getExceededDays(getDateFromIso8601String(occ.dateTimeEnd)) }}</td>
					<td class="tableCell">
						<div class="actionImagesContainer">
							<img src="../assets/images/accept.png" alt="Done" title="Done" style="margin-right: 20px; cursor: pointer;" @click.prevent="setResolution(occIndex, 'Done')" />
							<img src="../assets/images/delete.png" alt="Dismissed" title="Dismissed" style="cursor: pointer;" @click.prevent="setResolution(occIndex, 'Dismissed')" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'history',
	mixins: [globalSettings],
	components: {
	},
	data: function() {
		return {
			occurrences: [],
			events: []
		}
	},
	created: function() {
		this.getHistory();
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		setResolution: function(occIndex, newResolution) {
			var occurrence = this.occurrences[occIndex];
			this.showWait(true);

			this.$ky(this.apiUrl + "/SetOccurrenceResolution", {
				method: "GET",
				searchParams: {
					resolution: newResolution,
					parentEventId: occurrence.originalEventId,
					resolutionDateTimeStr: this.dateToIsoStr(this.getDateFromIso8601String(occurrence.dateTimeEnd), "DT"),
					timeZoneOffset: this.goptions.timeZoneOffset
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.showWait(false);
					if (!responseJson.success) {
						alert(responseJson.errorDescription);
					}
					else {
						this.getHistory();
					}
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		},
		getExceededDays: function(occDateTimeEnd) {
			return Math.floor(this.datesDiff(new Date(), occDateTimeEnd));
		},
		getParentEventName: function(occIndex) {
			var occ = this.occurrences[occIndex];
			var event = this.getParentEvent(occ.originalEventId);
			if (occIndex == 0) {
				return event.name;
			}

			if (occ.originalEventId != this.occurrences[occIndex - 1].originalEventId) {
				return event.name;
			}
			else {
				return "";
			}
		},
		getParentEvent: function(eventId) {
			return this.events.find(ev => ev.id == eventId);
		},
		getHistory: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetHistory", {
				method: "GET",
				searchParams: {
					userId: this.goptions.user.id,
					timeZoneOffset: this.goptions.timeZoneOffset
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.showWait(false);
					this.occurrences = responseJson.occurences;
					this.events = responseJson.parentEvents;
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		}
	}
}
</script>

<style>
.tableHead {
	width: 1px;
}
.tableCell {
	white-space: nowrap;
}
</style>