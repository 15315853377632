/*=============================
SL Slider
=============================== */
import * as d3 from "d3";

export default {
	name: "sslider",
	methods: {
		sslider: function() {
			var svg = null;
			var svgId = "sslider";
			var instance = this;
			var containerId = null;
			var sData = {
				backRectLayout: {
					borderWidth: 1,
					borderColor: "#101010",
					fillColor: "#EFEFEF",
					opacity: 0.5
				},
				trackLayout: {
					width: 500,
					height: 20,
					x: 0, // Will be computed
					y: 0, // Will be computed
					radius: 5,
					borderWidth: 4,
					borderColor: "#ff0000",
					fillColor: "#00ff00",
					class: null
				},
				thumbLayout: {	// !!! To be aligned to center according to the x coordinate
					width: 20,
					height: 40,
					x: 0, 	// Will be computed
					y: 0, // Will be computed
					radius: 5,
					borderWidth: 2,
					borderColor: "#555555",
					fillColor: "#ffff00",
					class: null
				},
				// sliderMargin: {
				// 	top: 10,
				// 	bottom: 30,
				// 	left: 5,
				// 	right: 5
				// },
				sliderMargin: {
					top: 10,
					bottom: 30,
					left: 0,
					right: 0
				},
				svgSize: {
					width: 0, // Will be computed
					height: 0	// Will be computed
				},
				range: {
					min: 0,
					max: 1000,
					step: 0
				},
				thumbs: [
					{
						value: 0,
						range: {		// Stores the range of x coordinates allowed for the thumb in case overlapping is not allowed. Will be initialized dynamically.
							min: 0,
							max: 0
						}
					}
				],
				scales: {
					val2pos: null
				},
				axes: {
					hAxis: null,
					hAxisGroup: null,
					hAxisTopPadding: 10
				},
				thumbOverlap: {
					overlap: false,
					pushNeighbor: true
				},
				events: {
					onDragStart: null,
					onDrag: null,
					onDragEnd: null
				}
			};
		
			var elements = {
				backRect: null,
				track: null,
				thumbs: [],
				sliderGroup: null
			};
		
			this.events = sData.events;
		
			this.create = function (sliderDivId, rootId, trackWidth) {
				sData.trackLayout.width = (trackWidth != null ? trackWidth : sData.trackLayout.width);
				containerId = sliderDivId;
				// Remove any previously created svg from the div container.
				instance.removeSvg();
				// Set the svg id.
				svgId = (rootId != null ? rootId : svgId);
		
				// Calculate the layout related data.
				initializeLayouts();
				// Create the scale based on the default values.
				createScale();
				// Create the svg canvas.
				svg = d3.select("#" + sliderDivId).append("svg").attr("id", svgId).attr("width", sData.svgSize.width).attr("height", sData.svgSize.height);
				// Generate the elements based on the default values.
				createElements();
		
				return instance;
			};
		
			this.removeSvg = function () {
				try {
					svg.remove();
				}
				catch (err) { }
			};
		
			function getSliderGroupHeight() {
				return Math.max(sData.trackLayout.height + sData.trackLayout.borderWidth, sData.thumbLayout.height + sData.thumbLayout.borderWidth);
			}
		
			function initializeLayouts() {
				sData.svgSize.width = sData.trackLayout.width + sData.thumbLayout.width + sData.thumbLayout.borderWidth + sData.backRectLayout.borderWidth + sData.sliderMargin.left + sData.sliderMargin.right;
				var thumbOutsideTrack = Math.max(0, ((sData.thumbLayout.height + sData.thumbLayout.borderWidth) - (sData.trackLayout.height + sData.trackLayout.borderWidth)) / 2);
		
				var sliderGroupHeight = Math.max(sData.trackLayout.height + sData.trackLayout.borderWidth, sData.thumbLayout.height + sData.thumbLayout.borderWidth);
				sData.svgSize.height = 2 * sData.backRectLayout.borderWidth + sliderGroupHeight + sData.sliderMargin.top + sData.sliderMargin.bottom;
		
				sData.trackLayout.x = sData.backRectLayout.borderWidth / 2 + sData.thumbLayout.width / 2 + sData.thumbLayout.borderWidth;
				sData.trackLayout.y = (thumbOutsideTrack > 0 ? thumbOutsideTrack + sData.backRectLayout.borderWidth + sData.trackLayout.borderWidth / 2 : sData.trackLayout.borderWidth / 2 + sData.backRectLayout.borderWidth);
		
				sData.thumbLayout.y = (thumbOutsideTrack > 0 ? sData.backRectLayout.borderWidth + sData.thumbLayout.borderWidth / 2 : sData.trackLayout.y + (sData.trackLayout.height - sData.thumbLayout.height) / 2);
			}
		
			function createElements() {
				// Create the background rectangle (having the SVG size).
				elements.backRect = svg.append("rect");
				elements.backRect.attr("id", svgId + "_backRect")
					.attr("width", sData.svgSize.width - sData.backRectLayout.borderWidth)
					.attr("height", sData.svgSize.height - sData.backRectLayout.borderWidth)
					.attr("x", sData.backRectLayout.borderWidth / 2)
					.attr("y", sData.backRectLayout.borderWidth / 2)
					.attr("stroke", sData.backRectLayout.borderColor)
					.attr("stroke-width", sData.backRectLayout.borderWidth)
					.attr("fill", sData.backRectLayout.fillColor)
					.attr("fill-opacity", sData.backRectLayout.opacity)
					;
		
				// Create the slider group.
				elements.sliderGroup = svg.append("g");
				elements.sliderGroup.attr("transform", "translate(" + sData.sliderMargin.left + "," + sData.sliderMargin.top + ")");
		
				// Create the track.
				elements.track = elements.sliderGroup.append("rect");
				elements.track.attr("id", svgId + "_track")
					.attr("width", sData.trackLayout.width)
					.attr("height", sData.trackLayout.height)
					.attr("x", sData.trackLayout.x)
					.attr("y", sData.trackLayout.y)
					.attr("rx", sData.trackLayout.radius)
					.attr("ry", sData.trackLayout.radius)
					.attr("stroke", sData.trackLayout.borderColor)
					.attr("stroke-width", sData.trackLayout.borderWidth)
					.attr("fill", sData.trackLayout.fillColor)
					.attr("fill-opacity", 1)
					.classed("sliderTrack", true)
					;
		
				return instance;
			}
		
			function createScale() {
				// Initialize scale. NOTE: It needs to be re-initialized if the range changes, or if the size of the track/thumb changes.
				sData.scales.val2pos = d3.scaleLinear()
					.domain([sData.range.min, sData.range.max])
					.range([
						sData.trackLayout.x - (sData.thumbLayout.width + sData.thumbLayout.borderWidth) / 2,
						sData.trackLayout.x + sData.trackLayout.width - (sData.thumbLayout.width + sData.thumbLayout.borderWidth) / 2])
					.clamp(true);
			}
		
			function recreateThumbs() {		// Recreates all the existing thumbs by using their current values.
				var thumbValues = [];
				for (var i = 0; i < sData.thumbs.length; i++) {
					thumbValues[i] = sData.thumbs[i].value;
				}
				instance.thumbs(thumbValues);
			}
		
			this.range = function (min, max) {
				sData.range.min = min;
				sData.range.max = max;
		
				// Recreate the scale for the new range.
				createScale();
		
				// Remove the axis group (if one was previously created)
				if (sData.axes.hAxisGroup != null) {
					sData.axes.hAxisGroup.remove();
				};
				// Create the axis group.
				sData.axes.hAxis = d3.axisBottom(sData.scales.val2pos);
				sData.axes.hAxisGroup = svg.append("g").attr("transform", "translate(" + (sData.sliderMargin.left + sData.thumbLayout.width / 2) + "," + (sData.sliderMargin.top + getSliderGroupHeight() + sData.axes.hAxisTopPadding) + ")");;
				sData.axes.hAxisGroup.call(sData.axes.hAxis);
		
				// Recreate any existing thumbs by using their current values.
				recreateThumbs();
		
				return instance;
			};
		
			this.thumbs = function (initialValues) {
				// Remove the thumb SVG elements
				for (var i = 0; i < elements.thumbs.length; i++) {
					elements.thumbs[i].remove();
				}
		
				elements.thumbs.length = 0; // Clear the thumbs array
				sData.thumbs.length = 0; 	// Clear the thumb values array
		
				// Check the values and normalize + issue log error if case.
				for (var i = 0; i < initialValues.length; i++) {
					var normVal = sData.scales.val2pos.invert(sData.scales.val2pos(initialValues[i]));
		
					// If a step is specified => set the value to the nearest step.
					if (sData.range.step > 0) {
						normVal = stickToStep(normVal);
					}
		
					if (initialValues[i] != normVal) {
						logError("Thumb value " + initialValues[i] + " normalized to " + normVal);
					}
					var v = { value: normVal, range: { min: 0, max: 0 } };
					sData.thumbs[i] = v;
				}
		
				// Sort the values array ascending, based on the x coordinate that corresponds to each value.
				// Like this, the array of thumbs (objects and values) will be sorted based on the initial position; the thumb "index" values will also reflect the initial positions.
				// This is useful for calculations if the thumbs cannot move one over the other.
				sData.thumbs.sort(function (a, b) {
					if (a.value == b.value) return 0;
					return (sData.scales.val2pos(a.value) > sData.scales.val2pos(b.value) ? 1 : -1);
				});
		
				for (var i = 0; i < sData.thumbs.length; i++) {
					var thumb = elements.sliderGroup.append("rect")
						.attr("id", svgId + "_thumb_" + i)
						.attr("width", sData.thumbLayout.width)
						.attr("height", sData.thumbLayout.height)
						.attr("y", sData.thumbLayout.y)
						.attr("rx", sData.thumbLayout.radius)
						.attr("ry", sData.thumbLayout.radius)
						.attr("stroke", sData.thumbLayout.borderColor)
						.attr("stroke-width", sData.thumbLayout.borderWidth)
						.attr("fill", sData.thumbLayout.fillColor)
						.attr("fill-opacity", 0.75)
						.classed("sliderThumb", true)
						;
		
					// If overlapping is not enabled, and if the last thumb in the array is on the most right x-position, and if it overlapps with at least one previous thumb => raise on by one the previous overlapping thumbs, in the revers order of their indexes.
					// [...]
		
					// Set the drag behavior.
					thumb.call(d3.drag().on("start", onDragStart).on("drag", onDragged).on("end", onDragEnd));
		
					thumb.datum({ "index": i, x: instance.thumbValueToPos(i), y: 0 });
					thumb.attr("x", instance.thumbValueToPos(i));
		
					elements.thumbs[i] = thumb;
				}
		
				calculateThumbRanges();
		
				solveExtremeRightSituation();
		
				return instance;
			}
		
			this.step = function (stepValue) {
				if (stepValue <= 0) {
					logError("Negative step value. Value not applied");
					return;
				}
				if (stepValue > Math.abs(sData.range.max - sData.range.min)) {
					logError("Step value " + stepValue + " > data range [" + sData.range.min + "," + sData.range.max + "]. Normalized to " + Math.abs(sData.range.max - sData.range.min));
					stepValue = Math.abs(sData.range.max - sData.range.min);
				}
		
				sData.range.step = stepValue;
				recreateThumbs();
		
				return instance;
			};
		
			this.overlap = function (allowOverlap, pushNeighbors) {
				sData.thumbOverlap.overlap = allowOverlap;
				sData.thumbOverlap.pushNeighbor = pushNeighbors;
				recreateThumbs();
		
				return instance;
			};
		
			function calculateThumbRanges() {
				if (sData.thumbOverlap.overlap) {	// Has sense only if overlapping is not allowd.
					return;
				}
		
				// We presume that the thumb objects array as well as the thumb values/ranges array are already ordered based on the initial thumbs x positions.
				var trackXMin = sData.scales.val2pos(sData.range.min);
				var trackXMax = sData.scales.val2pos(sData.range.max);
				for (var i = 0; i < elements.thumbs.length; i++) {
					if (i == 0) {									// Most left thumb
						sData.thumbs[i].range.min = trackXMin;
						sData.thumbs[i].range.max = (sData.thumbs.length > 1 ? sData.scales.val2pos(sData.thumbs[1].value) : trackXMax);
					}
					else if (i == elements.thumbs.length - 1) {		// Most right thumb
						sData.thumbs[i].range.max = trackXMax;
						sData.thumbs[i].range.min = (sData.thumbs.length > 1 ? sData.scales.val2pos(sData.thumbs[i - 1].value) : trackXMin);
					}
					else {											// Thumb positioned between two other thumbs
						sData.thumbs[i].range.min = sData.scales.val2pos(sData.thumbs[i - 1].value);
						sData.thumbs[i].range.max = sData.scales.val2pos(sData.thumbs[i + 1].value);
					}
				}
			}
		
			function onDragStart(d) {
				d3.select(this).raise().classed("thumbActive", true);
		
				// Trigger event if specified:
				if (sData.events.onDragStart != null) { sData.events.onDragStart(d); }
			}
		
			function trySetPosition(d, newPos) {
				// d: represents the data added to the SVG element with d3. The data is a JSON structure, like { index: 0, x: 123, y: 456 }, where:
				// index: the index of the element in the array sData.thumbs; x and y : coordinates of the SVG element.
				// d stores some data about the element in the element itself, making easier to obtain ad-hoc from the element instead of searching the element in the DOM then interrogate for x and y. Storing in "index" the index of the element in various data structures is also very helpful and simplifies the code.
				var neighborsPushed = false;
				var moveSense = 0;	// Related to "push" behavior. Values: -1 (left) or 1 (right)
		
				// Calculate the new value corresponding to the new coordinate.	Given clamp() is set on the val2pos scale, the thumb position is also kept between limits.
				var newVal = sData.scales.val2pos.invert(newPos);
		
				// Get move sense
				moveSense = (newPos < sData.scales.val2pos(sData.thumbs[d.index].value) ? -1 : 1);
		
				// If overlapping not allowed => normalize positon according to neighbour thumbs.
				if (!sData.thumbOverlap.overlap) {
					if (newPos < sData.thumbs[d.index].range.min) {
						if (sData.thumbOverlap.pushNeighbor && (sData.thumbs[d.index].range.min != sData.scales.val2pos(sData.range.min))) {
							// [...] Allow the thumb to go over the limit, only if if the limit is not the track itself. Store the need to push the left-behind thumb(s) the ones that are to be pushed.
							// At the end of this function, if neighborsPushed is true, the thmbs to be pushed will be identified (based on their range vs. the current tab position) and they will be moved to the
							// current thumb's position.
							neighborsPushed = true;
						}
						else {
							newPos = sData.thumbs[d.index].range.min;
							newVal = sData.scales.val2pos.invert(newPos);
						}
					}
					if (newPos > sData.thumbs[d.index].range.max) {
						if (sData.thumbOverlap.pushNeighbor && (sData.thumbs[d.index].range.max != sData.scales.val2pos(sData.range.max))) {
							// Allow the thumb to go over the limit, only if if the limit is not the track itself. Store the need to push the left-behind thumb(s) the ones that are to be pushed.
							// At the end of this function, if neighborsPushed is true, the thmbs to be pushed will be identified (based on their range vs. the current tab position) and they will be moved to the
							// current thumb's position.
							neighborsPushed = true;
						}
						else {
							newPos = sData.thumbs[d.index].range.max;
							newVal = sData.scales.val2pos.invert(newPos);
						}
					}
				}
				// If a step is specified => set the value to the nearest step.
				if (sData.range.step > 0) {
					newVal = stickToStep(newVal);
				}
		
				// Sync the newPos with the actual value.
				newPos = sData.scales.val2pos(newVal);
		
				// Set the (corrected if case) thumb position.
				elements.thumbs[d.index].attr("x", d.x = newPos);
		
				// Store the thumb's new value.
				sData.thumbs[d.index].value = newVal;
		
				// Check if any other thumbs are to be pushed. If so, push them to the new position.
				if (neighborsPushed) {
					// Identify the thumbs to push.
					var overlappedThumbs = [];	// Will store the thumbs under the current thumb.
					for (var index = 0; index < sData.thumbs.length; index++) {
						if (index == d.index) {
							continue;
						}
		
						switch (moveSense) {
							case -1: // The thumb was moved to the left
								// Consider only the thumbs with index < current index
								if (index > d.index) {
									continue;
								}
		
								if (sData.scales.val2pos(sData.thumbs[index].value) > newPos) {
									// Align value and position of the thumb with the new value and position of the current thumb.
									sData.thumbs[index].value = newVal;
									elements.thumbs[index].attr("x", elements.thumbs[index].datum().x = newPos);
									overlappedThumbs[overlappedThumbs.length] = elements.thumbs[index];
								}
		
								break;
							case 1: 	// The thumb was moved to the right
								// Consider the thumbs with index > current index
								if (index < d.index) {
									continue;
								}
		
								if (sData.scales.val2pos(sData.thumbs[index].value) < newPos) {
									// Align value and position of the thumb with the new value and position of the current thumb.
									sData.thumbs[index].value = newVal;
									elements.thumbs[index].attr("x", elements.thumbs[index].datum().x = newPos);
									overlappedThumbs[overlappedThumbs.length] = elements.thumbs[index];
								}
		
								break;
						}
					}
		
					// Arrange (z-order) the pushed thumbs, if more than 1.
					if (overlappedThumbs.length > 1) {
						// If more than 1 thumb was pushed, set the z-order of the covered thumbs so that they will be "naturally" draggable once thy are not anymore covered by the current thumb.
						switch (moveSense) {
							case -1: // The thumb was moved to the left
								for (var i = 0; i < overlappedThumbs.length; i++) {
									overlappedThumbs[i].raise();
								}
								elements.thumbs[d.index].raise();
								break;
							case 1: // The thumb was moved to the right
								for (var i = overlappedThumbs.length - 1; i >= 0; i--) {
									overlappedThumbs[i].raise();
								}
								elements.thumbs[d.index].raise();
								break;
						}
					}
		
				}
			}
		
			function onDragged(event, d) {
				// Set the position of the thumbnail based on the d (internal thumbnail data of the SVG element). d Is a JSON structure, with the fields index, x, y.
				// The value of "index" is the index of the thumb in various internal arrays of slider.
				// IMPORTANT:
				// The "d" parameter cannot be a simple JSON structure, but instead a reference to the actual SVG d3 data structure of an existing thumb. Can get that by calling datum(): element.datum(), where element is a reference to a DOM (SVG d3) element.
				// This is becaus it will be modified in this function and, being a reference, the modifications will be automatically applied to the internal thumb data.
				// IMPORTANT:
				// Dont't assume that the final thumb position will be for sure d.x. Reason:
				// Depending on the various settings of the slider control, the final position could differ from the one passed in d.x (normalization done in this function - follow the code.).
				// For example, the final position will be rectified if moving the thumb to the d.x position would move it outside the slider. Other options that could make the final position different than d.x: "sData.thumbOverlap.overlap" and "sData.thumbOverlap.pushNeighbor".
				trySetPosition(d, event.x);
				// Trigger event if specified:
				if (sData.events.onDrag != null) {
					sData.events.onDrag(d);
				}
			}
		
			// function onDragged(d) {
			// 	// Set the position of the thumbnail based on the d (internal thumbnail data of the SVG element). d Is a JSON structure, with the fields index, x, y.
			// 	// The value of "index" is the index of the thumb in various internal arrays of slider.
			// 	// IMPORTANT:
			// 	// The "d" parameter cannot be a simple JSON structure, but instead a reference to the actual SVG d3 data structure of an existing thumb. Can get that by calling datum(): element.datum(), where element is a reference to a DOM (SVG d3) element.
			// 	// This is becaus it will be modified in this function and, being a reference, the modifications will be automatically applied to the internal thumb data.
			// 	// IMPORTANT:
			// 	// Dont't assume that the final thumb position will be for sure d.x. Reason:
			// 	// Depending on the various settings of the slider control, the final position could differ from the one passed in d.x (normalization done in this function - follow the code.).
			// 	// For example, the final position will be rectified if moving the thumb to the d.x position would move it outside the slider. Other options that could make the final position different than d.x: "sData.thumbOverlap.overlap" and "sData.thumbOverlap.pushNeighbor".
			// 	trySetPosition(d, d3.event.x);
			// 	// Trigger event if specified:
			// 	if (sData.events.onDrag != null) {
			// 		sData.events.onDrag(d);
			// 	}
			// }
		
			function onDragEnd(d) {
				d3.select(this).classed("thumbActive", false);
		
				calculateThumbRanges();
		
				// Trigger event if specified:
				if (sData.events.onDragEnd != null) { sData.events.onDragEnd(d); }

				//
				var containerObj = document.getElementById(containerId); 
				if (containerObj != null) {
					var thumbValues = instance.get("values");
					containerObj.dispatchEvent(new CustomEvent("thumbDragged", {detail: {thumbValues: thumbValues.join()}}));
				}
			}
		
			function stickToStep(val) {
				return Math.round(val / sData.range.step) * sData.range.step;
			}
		
			function solveExtremeRightSituation() {
				/*
				Resolves the following limit situation: (1) There are more than one thumb. (2) The last indexed thumb is created on the extreme right. (3) There is at least the previous indexed tab that is
				created on the same coodrinate (extreme right). (4) No overlapping allowed
				In this case, the last thumb is on top and has a 0 lenghth range (it cannot be dragged). But the previous indexed thumb cannot be dragged neither, because it lies under the last thumb.
				==> Result: at least the last and the last-but-one thumbs cannot be dragged, they are blocked.
				==> Resolution: to raise() the thumbs liyng under the last thumb, in reverse index order. Like this, the lowest indexed thumb in the extreme position can be dragged, thus allowing the next one
				(in index order) to be dragged, etc.
				*/
				if (sData.thumbs.length <= 1) {
					return;
				}
		
				if (sData.thumbs[sData.thumbs.length - 1].value == sData.range.max) {
					for (var i = sData.thumbs.length - 2; i >= 0; i--) {
						if (sData.thumbs[i].value == sData.range.max) {
							elements.thumbs[i].raise();
						}
					}
				}
			}
			/*
					/// Convert thumb position to value
					/// ================================
					function pos2val(pos) {
						var val = sData.range.min + 1.0 * (pos + (sData.thumbLayout.width + sData.thumbLayout.borderWidth) / 2 - sData.trackLayout.x) / sData.trackLayout.width * (sData.range.max - sData.range.min);
						return val;
					};
			
					/// Convert value to thumb position
					/// ===============================
					function val2pos(val) {
						return sData.trackLayout.x - (sData.thumbLayout.width + sData.thumbLayout.borderWidth) / 2 + 1.0 * (val - sData.range.min) / (sData.range.max - sData.range.min) * sData.trackLayout.width;
					}
			*/
			/// Log error
			/// =========
			function logError(errMsg) {
				console.log(">>> Slider Err >>> " + errMsg);
			}
		
			this.thumbValueToPos = function (thumbIndex) {
				if (thumbIndex < 0 || thumbIndex >= sData.thumbs.lenght) {
					return 0;
				}
				return sData.scales.val2pos(sData.thumbs[thumbIndex].value);
			};
		
			this.setThumbValue = function (thumbIndex, newVal) {
				// Check that thumbIndex has a valid value.
				if (thumbIndex < 0 || thumbIndex > elements.thumbs.length - 1) {
					return;
				}
				// If value doesn't change, do nothing.
				if (sData.thumbs[thumbIndex].value == newVal) {
					return;
				}
		
				var newPos = sData.scales.val2pos(newVal);
				var thumb = elements.thumbs[thumbIndex];
				var d = thumb.datum();
				trySetPosition(d, newPos);
				calculateThumbRanges();
		
				return instance;
			};
		
			this.getThumbValue = function (thumbIndex) {
				// Check that thumbIndex has a valid value.
				if (thumbIndex < 0 || thumbIndex > elements.thumbs.length - 1) {
					return;
				}
				return sData.thumbs[thumbIndex].value;
			};
		
			this.get = function (infoType) {
				var retVal = null;
				switch (infoType) {
					case "values":
						retVal = [];
						for (var i = 0; i < sData.thumbs.length; i++) {
							retVal[i] = sData.thumbs[i].value;
						}
						break;
					case "positions":
						retVal = [];
						for (var i = 0; i < elements.thumbs.length; i++) {
							retVal[i] = { index: elements.thumbs[i].datum().index, value: elements.thumbs[i].datum().x };
						}
						break;
					case "thumbsData":
						retVal = [];
						for (var i = 0; i < sData.thumbs.length; i++) {
							retVal[i] = sData.thumbs[i];
						}
						break;
					default:
						break;
				}
				return retVal;
			};
		}
	}
}
