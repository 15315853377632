import { createStore } from 'vuex'

export default createStore({
	state: {
		goptions: {
			user: {},
			credentials: {
				validLogin: false,
			},
			timeZoneOffset: 0,
			eventFilter: 1,
			waitPopupVisible: false,
			notificationsEnabled: false,
			notificationsState: ""
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
