export const datesLib = {
	data() {
		return {
			DmyStringToDate: function(dateStr) {
				/*
				* Converts a string in d-m-y format, to a date, and returns it.
				* If the conversion fails, null is returned
				*/
				// Split and check the number of atoms.
				var arr = dateStr.split("-");
				if (arr.length != 3) {
					return null;
				}
				for (var i = 0; i < 3; i++) {
					if (isNaN(arr[i])) {
						return null;
					}
				}

				var day = 1 * arr[0];
				var month = 1 * arr[1];
				var year = 1 * arr[2];

				// Rough test - the Date object is generally too permissive, so this won't filter much.
				var isDate = true;
				var date = null;
				try {
					date = new Date(year, month - 1, day);
				}
				catch (ex) {
					isDate = false;
				}
				if (!isDate) {
					return null;
				}

				// Compare the date fields with the atom numeric values.
				var d = date.getDate();
				var m = date.getMonth();
				var y = date.getFullYear();
				if (date.getDate() != day || date.getMonth() != month - 1 || date.getFullYear() != year) {
					return null;
				}

				return date;
			},
			dateToIsoString: function(date) {
				var dd = String(date.getDate()).padStart(2, '0');
				var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = date.getFullYear();
				
				return yyyy + "-" + mm + '-' + dd;
			},
			UnixUtcToLocalDateString: function (d) {
				// IMPORTANT:
				// It looks like the Date created based un a Unix datetime considers the input (Unix datetime) as UTC, and applies
				// automatically the client's timezone to the resulting datetime.
				// Therefore, the resulting Date object represents the UTC time (the Unix datetime) automatically converted to local datetime.
				var date = new Date(d * 1000);
	
				var formattedDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2) + '.' + ('0' + date.getMilliseconds()).slice(-2);
				return formattedDate;
			},
			getUTCNowDateTime: function () {
				var now = new Date();
				return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
			},
			getTimeZoneOffset: function () {
				var d = new Date();
				return d.getTimezoneOffset(); // Return the timezone difference between UTC and Local Time, in minutes. Ex: If your time zone is GMT+2, -120 will be returned.
			},
			getDateFromIso8601String: function(dateStr) {
				/*
				Converts a string in the Iso8601 format (ex. "2021-01-12T08:00:00") to a
				javascript Date object.
				*/
				return new Date(dateStr);
			},
			isoDateStrToIso8601: function(isoDateStr) {
				return isoDateStr.replace(" ", "T");
			},
			dateToIsoStr: function(date, partsToInclude, dateSeparator, timeSeparator, dtSeparator) {
				/*
				Converts a javascript Date object to an ISO formatted string.
				*/
				// Defaults
				partsToInclude = (partsToInclude != null ? partsToInclude : "DT");
				dateSeparator = (dateSeparator != null ? dateSeparator : "-");
				timeSeparator = (timeSeparator != null ? timeSeparator : ":");
				dtSeparator = (dtSeparator != null ? dtSeparator : " ");

				var includeDate = partsToInclude.includes("D");
				var includeTime = partsToInclude.includes("T");

				var dayStr = String(date.getDate()).padStart(2, '0');
				var monthStr = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yearStr = date.getFullYear();
				var hourStr = String(date.getHours()).padStart(2, '0');
				var minuteStr = String(date.getMinutes()).padStart(2, '0');
				var secondStr = String(date.getSeconds()).padStart(2, '0');

				var datePartStr = [yearStr, monthStr, dayStr].join(dateSeparator);
				var timePartStr = [hourStr, minuteStr, secondStr].join(timeSeparator);

				return (includeDate ? datePartStr : "") + (includeDate && includeTime ? dtSeparator : "") + (includeTime ? timePartStr : "");
			},
			datesDiff: function(date1, date2, unit) {
				/*
				Returns the difference between two dates, either in days (default), hours, minutes
				or seconds.
				*/
				// Defaults
				unit = (unit != null ? unit : "d");		// "d" (days), "h" (hours), "m" (minutes), "s" (seconds)

				if (!["d", "h", "m", "s"].includes(unit)) {
					unit = "d";
				}

				var factor = 0;
				if (unit == "d") {
					factor = 1000*60*60*24;
				} else if (unit == "h") {
					factor = 1000*60*60;
				} else if (unit == "m") {
					factor = 1000*60;
				} else {		// "s" remained
					factor = 1000;
				}

				var millis = date1.getTime() - date2.getTime();
				return millis / factor;
			},
			addDays: function (date, days) {
				const copy = new Date(Number(date));
				copy.setDate(date.getDate() + days);
				return copy;
			},
			addMinutes: function(date, minutes) {
				const copy = new Date(Number(date));
				copy.setMinutes(date.getMinutes() + minutes);
				return copy;
			},
			formatIsoDateString(dateTimeStr, format, dateSeparator, includeTime) {
				try {
					// Formats: DMY, MDY, YMD etc.
					// 2021-03-01 11:12:13

					var arrDateParts = ["", "", ""];
					format = format.toUpperCase();
					var dateStr = dateTimeStr.substr(0, 10);
					var timeStr = dateTimeStr.substr(11, 8);

					var year = dateStr.substr(0, 4);
					var month = dateStr.substr(5, 2);
					var day = dateStr.substr(8, 2);

					var yearPos = format.indexOf("Y");
					var monthPos = format.indexOf("M");
					var dayPos = format.indexOf("D");

					arrDateParts[yearPos] = year;
					arrDateParts[monthPos] = month;
					arrDateParts[dayPos] = day;

					return arrDateParts.join(dateSeparator) + (includeTime ? " " + timeStr : "");
				}
				catch(ex) {
					return "??????";
				}
			}
		}
	}
}
