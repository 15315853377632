<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Settings</div>

		<div style="text-align: center">	<!-- Just to center the inner div -->
			<div id="divPasswordContainer" class="section-container" style="display:inline-block; margin-top:30px; margin-bottom: 15px;">	<!-- Important: "display:inline-block;" in order to have the panel shrinked (otherwise it will have the parent width (100%)) -->
					<div class="section-header">Push notifications</div>

					<div  v-html="notification.registrationLog" style="margin-top: 20px;"></div>

					<div style="margin-top: 20px;" v-if="notification.state.permission == notifPermission.default">
						Currently the notifications are not allowed.
						<br/>Click the following button to enable notifications from FarEvents.
						<br/>When prompted, click "Allow".
					</div>

					<div style="margin-top: 20px;" v-if="notification.state.permission == notifPermission.denied">
						Currently the notifications are blocked.
						<br/>In order to receive notifications from FarEvents, you must first
						<br/><b>manually allow the notifications</b> by selecting "Allow".
					</div>

					<div style="margin-top: 20px;" v-if="notification.state.permission == notifPermission.granted && notification.subscription == null">
						No current registration for push messages on this device
						<br/>In order to receive notifications from FarEvents, click the following button.
					</div>

					<div class="subsection-container" style="margin-top: 20px;">
						<button v-if="notification.state.permission == notifPermission.default || (notification.state.permission == notifPermission.granted && notification.subscription == null)" class="button" @click="registerAllChain()">Register</button>
						<button v-if="notification.subscription != null" class="button" @click="unregisterAllChain()">Unregister</button>
					</div>

					<div>
						<div style="margin-top: 20px;" v-for="subscription, index in userSubscriptions" :style="{'font-weight' : (isTheDeviceSubscription(subscription.endpoint) ? 'bold' : 'normal')}">
							({{ index + 1 }}) OS: {{ subscription.osInfo.platform }}, Browser: {{ subscription.osInfo.browserName }}
							<br/>{{ subscription.endpoint.substring(0, 75) + "..." }}
							<br/> Created: {{ formatIsoDateString(dateToIsoStr(addMinutes(getDateFromIso8601String(isoDateStrToIso8601(subscription.createdOn)), -getTimeZoneOffset())), "DMY", "-", true) }}
						</div>
					</div>
			</div>
		</div>

		<!-- Password change -->
		<div style="text-align: center">	<!-- Just to center the inner div -->
			<div id="divPasswordContainer" class="section-container" style="display:inline-block; margin-top:30px; margin-bottom: 15px;">	<!-- Important: "display:inline-block;" in order to have the panel shrinked (otherwise it will have the parent width (100%)) -->
				<div class="section-header">Password change</div>
					<div class="subsection-container" style="margin-top: 20px;">
						<table class="" style="/*width: 100%;*/">
							<tr>
								<th style="width:1px; white-space:nowrap">Current password</th>
								<td><input type="text" v-model="pass.crtPass" id="passCurrent" size="25" maxlength="50" /></td>
							</tr>
							<tr>
								<th style="width:1px; white-space:nowrap">New password</th>
								<td><input type="text" v-model="pass.newPass" id="passNew" size="25" maxlength="50" value="" /></td>
							</tr>
							<tr>
								<th style="width:1px; white-space:nowrap">Repeat new password</th>
								<td><input type="text" v-model="pass.newPassConfirm" id="passRepeat" size="25" maxlength="50" value="" /></td>
							</tr>
						</table>
					</div>

					<div class="subsection-container" style="margin-top: 20px; text-align: center">
						<a style="vertical-align: middle;" href="#" v-on:click.prevent="doSavePassword()" class="button">Set</a>

						<!-- <span id="spanSavedPass" style="float:right; color: #FF9900; font-weight: bold;">Saved</span> -->
					</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: 'settings',
	components: {
	},
	mixins: [globalSettings],
	data: function() {
		return {
			pass: {
				crtPass: "",
				newPass: "",
				newPassConfirm: ""
			},
			notification: {
				subscription: null,		// string - stringified current subscription, or null if no subscription
				state: {
					permission: "default",
					subscriptionToPushService: null,	// current subscription object, or null if no subscriptuon
					subscribedToServer: false
				},
				registrationLog: ""
			},
			userSubscriptions: []

		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		notificationsPermission: function() {
			return this.goptions.notificationsState;
		},
		notificationsEnabled: function() {
			return this.goptions.notificationsEnabled;
		}
	},
	watch: {
		notificationsPermission: function() {
			this.getNotificationChainState();

			if (this.notification.state.permission == this.notifPermission.granted) {
				// The permission state has been changed to "granted" => continue registration
				console.log("Permission changed to 'granted'");
				this.notification.registrationLog += "* Permission granted - OK";

				//var subscription = this.doSubscribeToNotifications();
				this.doSubscribeToNotifications()
				.then(subscription => {
					if (subscription == null) {
						this.notification.registrationLog += "<br/>* Subscribed to push service - NOT OK";	
					}
					else {
						this.notification.registrationLog += "<br/>* Subscribed to push service - OK";
	
						// Send subscription to the server.
						this.sendSubscriptionToServer(subscription)
					}
					
					// [...][De verificat]
					this.getNotificationChainState();
				})

			}

			if (this.notification.state.permission == this.notifPermission.denied) {
				// The permission state has been changed to "denied" => remove registration from server
				alert("Notification changed to Denied");
			}
		}
	},
	created: function() {
		// console.log(this.notifPermission.denied);
		this.getNotificationChainState();
		this.getServerSubscriptions();

		this.checkNotificationPermission();
		this.displaySubscription();
	},
	methods: {
			sendSubscriptionToServer(subscription) {
				//console.log("subscription", subscription);
				var subs = JSON.parse(JSON.stringify(subscription));

				this.$ky(this.apiUrl + "/AddPushSubscription", {
					method: "POST",
					searchParams: {
						userId: this.goptions.user.id,
						endpoint: subs.endpoint,
						p256dh: subs.keys.p256dh,
						auth: subs.keys.auth,

						osInfo: JSON.stringify(this.browserInfo()),
						sendBeforeDays: 2,
						timeOffset: this.goptions.timeZoneOffset,
						localTimeToSendNotification: "11:00:00"
					},
					cache: "no-cache",
					timeout: 3000,
					retry: 1
					})
					.json()
					.then(responseJson => {
						if (responseJson.success) {
							this.notification.registrationLog += "<br/>* Saved to server - OK";
						}
						else {
							this.notification.registrationLog += "<br/>* Saved to server - NOT OK";
						}

						// Refresh the list of subscriptions.
						this.getServerSubscriptions();

						this.showWait(false);
					})
					.catch(error => {
						this.showWait(false);
						alert("Error: " + error.message);
					});
			},
			getServerSubscriptions() {
				this.showWait(true);

				this.$ky(this.apiUrl + "/GetSubscriptions", {
					method: "GET",
					searchParams: {
						userId: this.goptions.user.id
					},
					cache: "no-cache",
					timeout: 3000,
					retry: 1
					})
					.json()
					.then(responseJson => {
						// Deserialize the os-related field, returned from server as JSON formatted string.
						for (let i = 0; i < responseJson.subscriptions.length; i++) {
							responseJson.subscriptions[i].osInfo = JSON.parse(responseJson.subscriptions[i].osInfo);
						}
						
						this.userSubscriptions = responseJson.subscriptions;

						this.showWait(false);
					})
					.catch(error => {
						this.showWait(false);
						alert("Error: " + error.message);
					});
			},
			async getNotificationChainState() {
			// Reset the states
			this.notification.state = {
					permission: this.notifPermission.default,
					subscriptionToPushService: false,
					subscribedToServer: false
			};

			// Check each state
			this.notification.state.permission = this.notificationsPermission;
			this.notification.state.subscriptionToPushService = await this.getSubscription();
		},
		registerAllChain() {
			this.notification.registrationLog = "";

			this.getNotificationChainState();
			if (this.notification.state.permission == this.notifPermission.default) {
				this.askNotificationPermission();
				return;
			}
			if (this.notification.state.permission == this.notifPermission.granted) {
				/*
				We are in the following situation:
				- the notifications are granted
				- there is no regidtration to the push service
				This can happen if
				- (after a previous successful refistration) the user blocks the notifications in the browser
				- the user comes back and enables (state "granted") the notifications in the browser.

				=> We have notifications granted, but no registration to the push service, and possible orphan registration on the server.
				*/
				this.doSubscribeToNotifications()
				.then(subscription => {
					if (subscription == null) {
						this.notification.registrationLog += "* Subscribed to push service - NOT OK";	
					}
					else {
						this.notification.registrationLog += "* Subscribed to push service - OK";
	
						// Send subscription to the server.
						this.sendSubscriptionToServer(subscription);
					}

					// [...][De verificat]
					this.getNotificationChainState();
				})
			}
		},
		async unregisterAllChain() {
			this.notification.registrationLog = "";

			// Delete subscription from device
			var result = await this.removeSubscription();
			console.log("deleteSubscription result: ", result);
			this.notification.subscription = null;
			this.notification.registrationLog += "* Removed from local - OK";

			// Delete subscription from server
			// - Identify the server id of the current subscription.
			var serverSubscription = this.userSubscriptions.find(el => el.endpoint == this.notification.state.subscriptionToPushService.endpoint);
			if (serverSubscription == null) {
				console.log("<br/>* No corresponding sunscription on server side");
				this.getNotificationChainState();
				this.getServerSubscriptions();
				this.notification.registrationLog += "<br/>* Removed from server - NOT OK - not found";
				return;
			}

			this.$ky(this.apiUrl + "/DeletePushSubscription", {
				method: "GET",
				searchParams: {
					subscriptionId: serverSubscription.id
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.getNotificationChainState();
					this.getServerSubscriptions();
					if (!responseJson.success) {
						this.notification.registrationLog += "<br/>* Removed from server - NOT OK - server error";
						alert("Error: " + responseJson.errorDescription);
					}
					else {
						this.notification.registrationLog += "<br/>* Removed from server - OK";
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		async doSubscribeToNotifications() {
			var subscription = await this.subscribeToNotifications();

			this.displaySubscription();

			return subscription;
		},
		async deleteSubscription() {
			// Delete from device
			var result = await this.removeSubscription();
			console.log("deleteSubscription result: ", result);
			this.displaySubscription();
		},
		displaySubscription() {
			this.getSubscription()
			.then(subs => {
				//console.log("Current subscription X: ", subs);
				this.notification.subscription = (subs == null ? null : JSON.stringify(subs, null, "\t"));
			});
		},
		askNotificationPermission() {
			this.requestNotificationPremissions()
			.then(() => {
				this.$forceUpdate();
			});	// Possible values: denied, granted, default
		},
		doShowSimpleNotification() {
			this.showSimpleNotification("A simple notification");
		},
		doSavePassword: function() {
			this.pass.crtPass = this.pass.crtPass.trim();
			this.pass.newPass = this.pass.newPass.trim();
			this.pass.newPassConfirm = this.pass.newPassConfirm.trim();
			if (this.pass.crtPass != this.goptions.user.password) {
				alert("Wrong current password");
				return;
			}
			if (this.pass.newPass != this.pass.newPassConfirm) {
				alert("New pass confirm does not match the new password");
				return;
			}
			if (this.pass.newPass == "") {
				alert("The password cannot be empty.");
				return;
			}
			if (parseInt(this.pass.newPass) + "" != this.pass.newPass) {
				alert("The password can contain only digits from 1 to 9 and cannot be empty.");
				return;
			}

			this.$ky(this.apiUrl + "/SetPassword", {
				method: "GET",
				searchParams: {
					userId: this.goptions.user.id,
					crtPass: this.pass.crtPass,
					newPass: this.pass.newPass
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert("Error: " + responseJson.errorDescription);
					}
					else {
						alert("New password saved");
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		isTheDeviceSubscription(endpoint) {
			if (this.notification.state.subscriptionToPushService == null) {
				return false;
			}
			return endpoint == this.notification.state.subscriptionToPushService.endpoint;
		}
	}
}
</script>

<style>

</style>
