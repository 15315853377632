import { createRouter, createWebHashHistory } from 'vue-router'

import Events from "../views/Events"
import Dashboard from "../views/Dashboard"
import History from "../views/History"
import Settings from "../views/Settings"
import Database from "../views/Database"
import Users from "../views/Users"
import ServerSubscriptions from "../views/ServerSubscriptions"
import Test from "../views/Test"
import About from "../views/About"
import TestRegistration from "../views/TestRegistration"
import LoginUserNamePassword from "../views/LoginUserNamePassword"
import Future from "../views/Future"

const routes = [
	{
		path: '/',
		name: 'default',
		component: LoginUserNamePassword
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard
	},
	{
		path: '/events',
		name: 'events',
		component: Events
	},
	{
		path: '/history',
		name: 'history',
		component: History
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings
	},
	{
		path: '/about',
		name: 'about',
		component: About
	},
	{
		path: '/database',
		name: 'database',
		component: Database
	},
	{
		path: '/users',
		name: 'users',
		component: Users
	},
	{
		path: '/test',
		name: 'test',
		component: Test
	},
	{
		path: '/testRegistration',
		name: 'testRegistration',
		component: TestRegistration
	},
	{
		path: "/login/:signOut?",
		name: "login",
		component: LoginUserNamePassword
	},
	{
		path: "/future",
		name: "future",
		component: Future
	},
	{
		path: "/serverSubscriptions",
		name: "serverSubscriptions",
		component: ServerSubscriptions
	}

]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
