<template>
	<div class="page-container">
		<div class="subtitle has-text-weight-semibold pageTitle">Dashboard</div>

		<div class="section-header">
			<div class="columns is-vcentered">
				<div class="column is-narrow"><label>Display date </label></div>
				<div class="column is-hidden-mobile is-narrow">
					<VueCtkDateTimePicker
						v-model="dtpicker.privateDateStr" 
						:first-day-of-week="dtpicker.firstDayOfWeek" 
						:label="dtpicker.label" 
						only-date
						input-size="sm" 
						no-label
						color="green"
						noClearButton
						no-button-now
						format="YYYY-MM-DD"
						formatted="YYYY-MM-DD"
						@validate="onDtPickerValidateClicked()"
						@is-hidden="onDtPickerHidden()"
						@now="onNow"
						>
					</VueCtkDateTimePicker>
				</div>
				<div class="column is-hidden-tablet is-narrow">
					<VueCtkDateTimePicker
						v-model="dtpicker.privateDateStr" 
						:first-day-of-week="dtpicker.firstDayOfWeek" 
						:label="dtpicker.label" 
						only-date
						input-size="sm" 
						no-label
						inline
						color="green"
						noClearButton
						:no-keyboard="true"
						format="YYYY-MM-DD"
						formatted="YYYY-MM-DD"
						@validate="onDtPickerValidateClicked()"
						@is-hidden="onDtPickerHidden()"
						@input="onDtPickerInput"
						@now="onNow"
						>
					</VueCtkDateTimePicker>
				</div>
				<div class="column is-hidden-mobile is-narrow has-text-weight-normal has-text-left">
					<a href="#" v-on:click.prevent="displayToday()" class="button">Show Today</a>
				</div>
				<!-- <div class="column has-text-weight-normal has-text-right">
					<a href="#" v-on:click.prevent="getOccurrences()" class="button">Refresh</a>
				</div> -->
			</div>
		</div>

		<div class="occurrencesContainer">
			<Occurrence v-for="(occurrence, index) in occurrences" :key="occurrence.eventId" v-bind="occurrences[index]" containerBorder="none" @editOccurrence="doEditOccurrence" style="margin-top: 25px;" />
		</div>

		<ModalPopupFade columns="6" transDuration="0.5s" popTitle="Set resolution" :visible="resolutionInfo.editResolutionVisible" @askClose="editOccCancel">
			<div class="columns is-vcentered" style="margin-top: 20px;">
				<div class="column" style="text-align: center;">
					<a v-if="resolutionInfo.occurrenceInfo.currentResolution != 'None'" href="#" class="button"  @click.prevent="setResolution('None')">None</a>
					<a v-if="resolutionInfo.occurrenceInfo.currentResolution != 'Done'" href="#" class="button"  @click.prevent="setResolution('Done')">Done</a>
					<a v-if="resolutionInfo.occurrenceInfo.currentResolution != 'Dismissed'" href="#" class="button"  @click.prevent="setResolution('Dismissed')">Dismissed</a>
					<a href="#" class="button" style="margin-left: 20px;" @click.prevent="editOccCancel">Cancel</a>
				</div>
			</div>
		</ModalPopupFade>
	</div>
</template>

<script>
import Occurrence from "@/components/Occurrence.vue";
import { globalSettings } from "@/mixins/globalSettings";
import ModalPopupFade from "@/components/controls/ModalPopupFade.vue";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "dashboard",
	mixins: [globalSettings],
	components: {
		Occurrence,
		ModalPopupFade
	},
	data: function() {
		return {
			occurrences: [],
			dtpicker: {							// This is needed because the datetime picker requires a number (not a text) as value of firstDayOfWeek; similar - it requires "real" null value (if case), but not the "null" (string) value for label.
				firstDayOfWeek: 1,
				label: null,
				privateDateStr: ""
			},
			displayDateStr: "2020-01-25",
			resolutionInfo: {
				editResolutionVisible: false,
				occurrenceInfo: {}
			}
		}
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	created: function() {
		this.displayToday();
	},
	methods: {
		displayToday: function() {
			this.displayDateStr = this.dateToIsoString(new Date());	
			this.dtpicker.privateDateStr = this.displayDateStr;
			this.getOccurrences();
		},
		setResolution: function(newResolution) {
			this.$ky(this.apiUrl + "/SetOccurrenceResolution", {
				method: "GET",
				searchParams: {
					resolution: newResolution,
					parentEventId: this.resolutionInfo.occurrenceInfo.eventId,
					resolutionDateTimeStr: this.displayDateStr + " " + this.dateToIsoStr(new Date(), "T"),
					timeZoneOffset: this.goptions.timeZoneOffset
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					//console.log("setResolution- done");
					if (!responseJson.success) {
						alert(responseJson.errorDescription);
					}
					else {
						this.getOccurrences();
						this.resolutionInfo.editResolutionVisible = false;
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		editOccCancel: function() {
			this.resolutionInfo.editResolutionVisible = false;
		},
		doEditOccurrence: function(ev) {
			this.resolutionInfo.occurrenceInfo = ev;
			this.resolutionInfo.editResolutionVisible = true;
		},
		onNow: function(ev) {
			// console.log("=====> onNow");
			// console.log(ev);
		},
		onDtPickerInput: function(ev) {
			this.displayDateStr = this.dtpicker.privateDateStr;
			this.getOccurrences();
			// console.log("=====> onDtPickerInput");
			// console.log(ev);
			// console.log("*** " + this.displayDateStr);
			// console.log("*** " + this.dtpicker.privateDateStr);
		},
		onDtPickerValidateClicked: function() {
			// This is where we update the job date-time
			this.displayDateStr = this.dtpicker.privateDateStr;
			this.getOccurrences();
		},
		onDtPickerHidden: function() {
			// Purpose: If the dt picker was not closed by clicking the validate button, its value is reset to the job's date-time. So thet the job datetime is only updated when the validate button was clicked.
			// If the dt picker WAS closed by clicking the validate button, then the values are however already equel - see the onDtPickerValidateClicked handler.
			this.dtpicker.privateDateStr = this.displayDateStr;
		},
		getOccurrences: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/GetOccurrencesForDate", {
				method: "GET",
				searchParams: {
					userId: this.goptions.user.id,
					dateStr: this.displayDateStr,
					timeZoneOffset: this.goptions.timeZoneOffset
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.occurrences = responseJson.occurrences;
					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});

		}
	}
}
</script>

<style>
	div .occurrencesContainer {
		/* background-color: #fafafa;
		border: 2px dotted black;
		padding: 15px;
		border-radius: 15px; */
		padding: 0px;
		max-width: 1500px;
		margin: auto;
	}
</style>